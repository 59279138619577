<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12">
          <label
            :class="{
              'p-error': v$.form.obat.$invalid && submitted,
            }"
            >Obat *</label
          >
          <AutoComplete
            v-model="form.obat"
            :min-length="2"
            :suggestions="acObat"
            :class="{
              'p-invalid': v$.form.obat.$invalid && submitted,
            }"
            field="nama"
            class="w-full"
            input-class="w-full"
            autofocus
            @complete="searchObat($event)"
            @item-select="selectObat"
          >
            <template #item="slotProps">
              <div>
                {{ slotProps.item.kode }} - {{ slotProps.item.nama }}<br />
                Stock tersedia:
                {{ slotProps.item.stock_available }}
              </div>
            </template>
          </AutoComplete>
          <small
            v-if="
              (v$.form.obat.$invalid && submitted) ||
              v$.form.obat.$pending.$response
            "
            class="p-error"
            >{{ v$.form.obat.hasKode.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label
            :class="{
              'p-error': v$.form.satuan.$invalid && submitted,
            }"
          >
            Satuan</label
          >
          <Dropdown
            v-model="form.satuan"
            :options="list_satuan"
            :class="{
              'p-invalid': v$.form.satuan.$invalid && submitted,
            }"
            optionValue="id"
            optionLabel="nama"
            class="w-full"
            input-class="w-full"
            @change="selectSatuan"
          />
          <small
            v-if="
              (v$.form.satuan.$invalid && submitted) ||
              v$.form.satuan.$pending.$response
            "
            class="p-error"
            >{{ v$.form.satuan.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-2">
          <label
            :class="{
              'p-error': v$.form.quantity.$invalid && submitted,
            }"
            >Qty *</label
          >
          <InputNumber
            v-model="form.quantity"
            :class="{
              'p-invalid': v$.form.quantity.$invalid && submitted,
            }"
            :min="1"
            class="w-full"
            input-class="w-full"
            @input="updateQty"
          />
          <small
            v-if="
              (v$.form.quantity.$invalid && submitted) ||
              v$.form.quantity.$pending.$response
            "
            class="p-error"
            >{{ v$.form.quantity.required.$message }}</small
          >

          <span v-if="v$.form.quantity.$error && submitted">
            <span
              v-for="(error, index) of v$.form.quantity.$errors"
              :key="index"
            >
              <small class="p-error">{{ error.$message }}</small>
            </span>
          </span>
        </div>
        <div class="field col-12 md:col-3">
          <label>QTY terkecil</label>
          <InputText
            v-model="form.quantity_tkcl"
            type="text"
            class="w-full"
            input-class="w-full"
            disabled
          />
        </div>
        <div class="field col-12 md:col-3">
          <label>Satuan terkecil</label>
          <InputText
            v-model="form.satuan_tkcl"
            type="text"
            class="w-full"
            disabled
          />
        </div>
        <div class="field col-12 md:col-3">
          <label
            :class="{
              'p-error': v$.form.harga.$invalid && submitted,
            }"
          >
            Harga
          </label>
          <InputNumber
            v-model="form.harga"
            :minFractionDigits="0"
            :class="{
              'p-invalid': v$.form.harga.$invalid && submitted,
            }"
            class="w-full"
            input-class="w-full"
            mode="currency"
            currency="IDR"
            locale="id-ID"
            disabled
          />
          <small
            v-if="
              (v$.form.harga.$invalid && submitted) ||
              v$.form.harga.$pending.$response
            "
            class="p-error"
            >{{ v$.form.harga.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-3">
          <label>Diskon (%)</label>
          <InputNumber
            v-model="form.diskon"
            :minFractionDigits="2"
            :min="0"
            :max="100"
            locale="id-ID"
            class="w-full"
            input-class="w-full"
            @input="updateDiskon"
            @keyup.enter="handleSubmit(!v$.$invalid)"
          />
        </div>
        <div class="field col-12 md:col-3">
          <label>diskon (Rp)</label>
          <InputNumber
            v-model="form.harga_diskon_display"
            :minFractionDigits="0"
            class="w-full"
            input-class="w-full"
            mode="currency"
            currency="IDR"
            locale="id-ID"
            disabled
          />
        </div>
        <div class="field col-12 md:col-3">
          <label>Harga akhir</label>
          <InputNumber
            v-model="form.dpp"
            :minFractionDigits="0"
            class="w-full"
            input-class="w-full"
            mode="currency"
            currency="IDR"
            locale="id-ID"
            disabled
          />
        </div>
        <div class="field col-12 md:col-3">
          <label>Jumlah</label>
          <InputNumber
            v-model="form.harga_total"
            :minFractionDigits="0"
            class="w-full"
            input-class="w-full"
            mode="currency"
            currency="IDR"
            locale="id-ID"
            disabled
          />
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Tambahkan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required, minValue } from '@vuelidate/validators'
import { isObject } from '@/helpers'
import errorHandler from '@/helpers/error-handler'
import angkaTerbilang from '@/helpers/terbilang'
import ObatService from '@/services/ObatService'
import SettingService from '@/services/SettingService'

const hasKode = (value) => isObject(value, 'kode')

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    kodeHarga: {
      type: String,
      default: '',
    },
    satuan: {
      type: Array,
      default() {
        return []
      },
    },
    golongan: {
      type: String,
      default: '',
    },
  },
  created() {
    this.obatService = new ObatService()
    const settingService = new SettingService()
    settingService.get('?filter[key]=PPN').then((res) => {
      this.ppn = res.data.data.value
    })
  },
  data() {
    return {
      submitted: false,
      acObat: null,
      list_satuan: this.satuan,
      form: this.item,
      qty_satuan: 0,
      ppn: 0,
    }
  },
  mounted() {
    if (this.item.obat && this.item.obat.satuan) {
      this.list_satuan = this.item.obat.satuan
      this.qty_satuan = this.item.qty_satuan
    }
  },
  computed: {
    title() {
      return this.item && this.item.id === 0 ? 'Tambah barang' : 'Edit barang'
    },
  },
  methods: {
    searchObat(e) {
      //`?filter[nama]=${e.query}&filter[golongan]=${this.golongan}&filter[status]=aktif&filter[has_stock]=1`
      this.obatService
        .get(
          `?filter[nama]=${e.query}&filter[golongan]=${this.golongan}&filter[status]=aktif`
        )
        .then((res) => {
          this.acObat = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Obat', this)
        })
    },
    selectObat(e) {
      this.form.satuan_tkcl = e.value.satuan_tkcl.nama
      this.form.harga = this.getHarga(e.value.margin, this.kodeHarga)
      this.list_satuan = e.value.satuan_order
      if (this.list_satuan.length > 0) {
        this.form.satuan = this.list_satuan[0].id
        this.qty_satuan = this.list_satuan[0].quantity
        this.form.quantity_tkcl =
          this.form.quantity * (this.qty_satuan ? this.qty_satuan : 0)
      }
      this.hitungTotal()
    },
    selectSatuan(e) {
      const res = this.list_satuan.find((item) => item.id === e.value)
      if (res && res.quantity) {
        this.qty_satuan = res.quantity
        this.form.quantity_tkcl =
          this.form.quantity * (this.qty_satuan ? this.qty_satuan : 0)
        this.hitungTotal()
      }
    },
    getHarga(margin, kode) {
      let harga = 0
      switch (kode) {
        case 'A':
          harga = margin.harga_jual_A
          break
        case 'B':
          harga = margin.harga_jual_B
          break
        case 'C':
          harga = margin.harga_jual_C
          break
        case 'D':
          harga = margin.harga_jual_D
          break
        case 'E':
          harga = margin.harga_jual_E
          break
        case 'F':
          harga = margin.harga_jual_F
          break
      }
      /*
      const ppn = this.ppn / 100
      return Math.round(harga / (1 + ppn))
      */
      return harga
    },
    updateQty(e) {
      this.form.quantity = e.value
      this.form.quantity_tkcl =
        this.form.quantity * (this.qty_satuan ? this.qty_satuan : 0)
      this.hitungTotal()
    },
    updateDiskon(e) {
      this.form.diskon = e.value
      this.hitungTotal()
    },
    hitungTotal() {
      // const ppn = this.ppn / 100
      const qty_satuan = this.qty_satuan
        ? this.qty_satuan
        : this.form.qty_satuan
      const qty = this.form.quantity * (qty_satuan ? qty_satuan : 0)
      this.form.harga_diskon =
        this.form.harga - Math.round(this.form.harga * (this.form.diskon / 100))
      this.form.harga_diskon_display = Math.round(
        this.form.harga * (this.form.diskon / 100)
      )
      //this.form.dpp = this.form.harga_diskon * (1 + this.ppn / 100)
      this.form.dpp = this.form.harga_diskon * qty

      this.form.harga_total =
        this.form.dpp + Math.floor(this.form.dpp * (this.ppn / 100))
    },
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      if (this.form.harga === 0) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Harga Obat',
          detail: 'Harga obat kosong!',
          life: 3000,
        })
        return
      }

      this.hitungTotal()
      const form = Object.assign({}, this.form)
      form.obat_id = this.form.obat.id
      form.nama_obat = this.form.obat.nama

      const res = this.list_satuan.find(
        (satuan) => satuan.id === this.form.satuan
      )

      if (res) {
        form.satuan_id = res.id
        form.satuan = res.nama
      }

      form.keterangan = form.quantity
        ? angkaTerbilang(form.quantity).toUpperCase()
        : ''

      delete form.obat

      this.$emit('save', form)

      this.form = {
        id: 0,
        obat: { kode: '', nama: '' },
        obat_id: 0,
        quantity: 1,
        quantity_tkcl: 0,
        diskon: 0,
        diskon_harga: 0,
        harga: 0,
        harga_diskon: 0,
        harga_diskon_display: 0,
        dpp: 0,
        harga_total: 0,
        keterangan: '',
        satuan: { id: 0, nama: '' },
      }
    },
  },
  validations() {
    return {
      form: {
        obat: {
          hasKode: helpers.withMessage('Obat harus diisi.', hasKode),
        },
        quantity: {
          required: helpers.withMessage('Jumlah harus diisi.', required),
          minValue: helpers.withMessage('Jumlah minimal 1.', minValue(1)),
        },
        satuan: {
          required: helpers.withMessage('Satuan harus diisi.', required),
        },
        harga: {
          required: helpers.withMessage('Harga harus diisi.', required),
        },
      },
    }
  },
}
</script>
