<template>
  <div>
    <form @submit.prevent="save(!v$.$invalid)">
      <div class="card bg-white">
        <div class="flex justify-content-end mb-2">
          <Button
            v-if="
              $ability.can('sales.payment.create') ||
              (form.id > 0 && $ability.can('sales.payment.update'))
            "
            :loading="isLoadingSaveHeader"
            icon="pi pi-save"
            class="mr-2"
            label="Simpan"
            type="submit"
            :disabled="disabledSave"
          />
          <Button
            :loading="isLoadingInvoice"
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Kontra bon / Faktur"
            @click="add"
          />
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'PembayaranPiutang' })"
          />
        </div>
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="formgrid grid">
              <div class="field col-12 md:col-3">
                <label
                  :class="{
                    'p-error': v$.form.sp_at.$invalid && submitted,
                  }"
                >
                  Tanggal</label
                >
                <Calendar
                  v-model="form.sp_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                  :class="{
                    'p-invalid': v$.form.sp_at.$invalid && submitted,
                  }"
                />
                <small
                  v-if="
                    (v$.form.sp_at.$invalid && submitted) ||
                    v$.form.sp_at.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.sp_at.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-3">
                <label>No Bukti</label>
                <InputText v-model="form.sp_no" class="w-full" disabled />
              </div>
              <div class="field col-12 md:col-6">
                <label
                  :class="{
                    'p-error': v$.form.pelanggan.$invalid && submitted,
                  }"
                >
                  Pelanggan
                </label>
                <AutoComplete
                  v-model="form.pelanggan"
                  :suggestions="acPelanggan"
                  :min-length="2"
                  :class="{
                    'p-invalid': v$.form.pelanggan.$invalid && submitted,
                  }"
                  class="w-full"
                  input-class="w-full"
                  field="nama"
                  @complete="searchPelanggan($event)"
                  @item-select="selectPelanggan"
                >
                  <template #item="slotProps">
                    <div>
                      <strong>
                        {{ slotProps.item.kode }} - {{ slotProps.item.nama }}
                      </strong>
                    </div>
                    <div>Alamat: {{ slotProps.item.alamat }}</div>
                    <div>Salesman: {{ slotProps.item.salesman.nama }}</div>
                  </template>
                </AutoComplete>
                <small
                  v-if="
                    (v$.form.pelanggan.$invalid && submitted) ||
                    v$.form.pelanggan.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.pelanggan.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-8">
                <label>Salesman</label>
                <InputText v-model="form.salesman" class="w-full" disabled />
              </div>
              <div class="field col-12 md:col-4">
                <label>Kode</label>
                <InputText
                  v-model="form.kode_salesman"
                  class="w-full"
                  disabled
                />
              </div>
              <div class="field col-7">
                <label>Pembayaran</label>
                <div class="formgroup-inline">
                  <div class="field-radiobutton">
                    <RadioButton
                      name="tunai"
                      value="tunai"
                      v-model="pembayaran"
                      id="pembayaran-0"
                    />
                    <label for="pembayaran-0">Tunai</label>
                  </div>
                  <div class="field-radiobutton">
                    <RadioButton
                      name="giro"
                      value="giro"
                      v-model="pembayaran"
                      id="pembayaran-1"
                    />
                    <label for="pembayaran-1">Giro/Cek</label>
                  </div>
                  <div class="field-radiobutton">
                    <RadioButton
                      name="transfer"
                      value="transfer"
                      v-model="pembayaran"
                      id="pembayaran-2"
                    />
                    <label for="pembayaran-2">Transfer</label>
                  </div>
                </div>
              </div>
              <!--
              <div class="field col-5" v-if="form.pembayaran === 'giro'">
                <div class="field-checkbox mb-1">
                  <Checkbox id="tgl-bayar" v-model="isTglBayar" :binary="true" />
                  <label for="tgl-bayar">Tanggal Bayar</label>
                </div>
                <Calendar
                  v-model="form.settle_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                  :disabled="!isTglBayar"
                />
              </div>
              -->
              <div
                v-if="form.pembayaran === 'tunai'"
                class="field col-6 md:col-6"
              >
                <label>Kode kas</label>
                <Dropdown
                  v-model="form.kas_id"
                  :options="list_daftar_kas"
                  optionValue="id"
                  optionLabel="nama"
                  class="w-full"
                >
                  <template #option="slotProps">
                    <div class="p-dropdown-kas-option">
                      <span v-if="slotProps.option.rekening">
                        {{ slotProps.option.nama }}&nbsp;({{
                          slotProps.option.rekening
                        }})
                      </span>
                      <span v-else>{{ slotProps.option.nama }}</span>
                    </div>
                  </template>
                </Dropdown>
              </div>
              <div
                v-if="
                  form.pembayaran === 'transfer' || form.pembayaran === 'giro'
                "
                class="field col-12 md:col-7"
              >
                <label>Kode Bank</label>
                <Dropdown
                  v-model="form.bank_id"
                  :options="list_kode_bank"
                  optionValue="id"
                  optionLabel="nama"
                  class="w-full"
                >
                  <template #option="slotProps">
                    <div class="p-dropdown-kas-option">
                      <span v-if="slotProps.option.rekening">
                        {{ slotProps.option.nama }}&nbsp;({{
                          slotProps.option.rekening
                        }})
                      </span>
                      <span v-else>{{ slotProps.option.nama }}</span>
                    </div>
                  </template>
                </Dropdown>
                <small
                  v-if="
                    (v$.form.bank_id.$invalid && submitted) ||
                    v$.form.bank_id.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.bank_id.required.$message }}</small
                >
              </div>
              <div
                v-if="form.pembayaran === 'giro'"
                class="field col-12 md:col-7"
              >
                <label
                  :class="{
                    'p-error': v$.form.giro_no.$invalid && submitted,
                  }"
                >
                  No Giro
                </label>
                <InputText
                  :class="{
                    'p-error': v$.form.giro_no.$invalid && submitted,
                  }"
                  v-model="form.giro_no"
                  class="w-full"
                />
                <small
                  v-if="
                    (v$.form.giro_no.$invalid && submitted) ||
                    v$.form.giro_no.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.giro_no.required.$message }}</small
                >
              </div>
              <div
                v-if="form.pembayaran === 'giro'"
                class="field col-12 md:col-5"
              >
                <label
                  :class="{
                    'p-error': v$.form.giro_at.$invalid && submitted,
                  }"
                >
                  Tanggal Jatuh Tempo
                </label>
                <Calendar
                  v-model="form.giro_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                  :class="{
                    'p-error': v$.form.giro_at.$invalid && submitted,
                  }"
                />
                <small
                  v-if="
                    (v$.form.giro_at.$invalid && submitted) ||
                    v$.form.giro_at.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.giro_at.required.$message }}</small
                >
              </div>

              <div
                v-if="form.pembayaran === 'giro' && this.form.id > 0"
                class="field col-12 md:col-3"
              >
                <label>Status Giro</label>
                <Dropdown
                  v-model="form.giro_status"
                  :options="list_giro_status"
                  optionValue="value"
                  optionLabel="label"
                  optionDisabled="disabled"
                  class="w-full"
                >
                  <template #option="slotProps">
                    {{ slotProps.option.label }}
                  </template>
                </Dropdown>
              </div>
              <div
                v-if="form.pembayaran === 'giro' && this.form.id > 0"
                class="field col-12 md:col-4"
              >
                <label>Tanggal Status Giro</label>
                <Calendar
                  v-model="form.giro_status_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                />
              </div>
              <div
                v-if="form.pembayaran === 'giro' && this.form.id > 0"
                class="field col-12 md:col-5"
              >
                <label> Keterangan Giro </label>
                <InputText v-model="form.giro_keterangan" class="w-full" />
              </div>

              <div class="field col-6 md:col-6">
                <label>Jumlah</label>
                <InputNumber
                  ref="jumlah"
                  v-model="jumlah"
                  :minFractionDigits="0"
                  mode="currency"
                  currency="IDR"
                  locale="id-ID"
                  class="w-full"
                  input-class="w-full"
                  :max="jumlahPiutang"
                  @input="onInputJumlah"
                />
              </div>
            </div>
          </div>
        </div>
        <grid-pembayaran-piutang-detail
          :items="form.detailNew"
          :loading="isLoading"
          :show-button="true"
          :cn="form.cn"
          :dn="form.dn"
          :materai="form.materai"
          @items="onUpdateDetail"
          @edit="onEditInvoice"
          @delete="onConfirmDeletion"
          @cn="onUpdateCn"
          @dn="onUpdateDn"
          @materai="onUpdateMaterai"
        />
      </div>
    </form>
    <Dialog
      header="Tambah kontra bon/faktur"
      v-model:visible="dialogAdd"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '70vw' }"
      modal
      maximizable
    >
      <form-list-pembayaran-piutang
        ref="formListPembayaranPiutang"
        :kontra="list_kontra"
        :kontra-exclude-list="list_kb_no"
        :invoice="list_invoice"
        :invoice-exclude-list="list_invoice_no"
        :retur="list_retur"
        :retur-exclude-list="list_retur_no"
        @close="onCloseFormKontra"
        @save="onSaveDetail"
      />
      <template #footer>
        <div class="flex justify-content-end align-items-end">
          <Button
            label="Tutup"
            icon="pi pi-times"
            class="p-button-text mr-2"
            @click="onCloseFormKontra"
          />
          <Button
            label="Tambahkan"
            icon="pi pi-check"
            @click="this.$refs.formListPembayaranPiutang.add()"
          />
        </div>
      </template>
    </Dialog>
    <Dialog
      header="Edit kontra bon/faktur"
      v-model:visible="dialogEdit"
      :breakpoints="{ '960px': '50vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      modal
    >
      <form-edit-kontrabon :item="formEdit" @save="onUpdateInvoice" />
    </Dialog>
    <Dialog
      header="Hapus kontra bon dari daftar pembayaran ?"
      v-model:visible="dialogDelete"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
    >
      <div>
        <span
          >Faktur <strong>{{ invoice.si_no }}</strong> akan dihapus dari daftar
          pembayaran. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogDelete = false"
          class="p-button-text mr-2"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeleteItem"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import GridPembayaranPiutangDetail from '@/components/penjualan/GridPembayaranPiutangDetail'
import FormListPembayaranPiutang from '@/components/penjualan/FormListPembayaranPiutang'
import FormEditKontrabon from '@/components/penjualan/FormEditKontrabon'
import PelangganService from '@/services/PelangganService'
import DaftarKasService from '@/services/DaftarKasService'
import KontraService from '@/services/KontraPenjualanService'
import ReturService from '@/services/ReturPenjualanService'
import PiutangService from '@/services/PiutangService'
import InvoiceService from '@/services/SalesInvoiceService'
import { helpers, required, requiredIf } from '@vuelidate/validators'
import Hotkey from '@/components/Hotkey'
import errorHandler from '@/helpers/error-handler'
import dayjs from 'dayjs'
import { ABILITY_TOKEN } from '@casl/vue'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  inject: {
    $ability: { from: ABILITY_TOKEN },
  },
  components: {
    GridPembayaranPiutangDetail,
    FormListPembayaranPiutang,
    FormEditKontrabon,
    Hotkey,
  },
  data() {
    return {
      pembayaranService: null,
      kontraService: null,
      pelangganService: null,
      submitted: false,
      isLoading: false,
      isLoadingSaveHeader: false,
      isLoadingInvoice: false,
      //isTglBayar: false,
      pembayaran: 'tunai',
      form: {
        id: 0,
        sp_at: new Date(),
        sp_no: null,
        pelanggan: null,
        pembayaran: 'tunai',
        cn: 0,
        dn: 0,
        materai: 0,
        giro_no: '-',
        giro_at: new Date(),
        giro_status: null,
        giro_status_at: null,
        giro_keterangan: null,
        //settle_at: null,
        detail: [],
        detailNew: [],
      },
      deleted: [],
      acPelanggan: [],
      list_kontra: [],
      list_retur: [],
      list_invoice: [],
      list_giro_status: [],
      daftar_kas: [],
      dialogAdd: false,
      dialogEdit: false,
      dialogDelete: false,
      formEdit: {},
      invoice: null,
      pelangganTop: 0,
      jumlah: 0,
    }
  },
  created() {
    this.pelangganService = new PelangganService()
    this.kontraService = new KontraService()
    this.pembayaranService = new PiutangService()
    this.returService = new ReturService()
    this.invoiceService = new InvoiceService()
  },
  computed: {
    list_kb_no() {
      return this.form.detail.map((x) => x.kb_no)
    },
    list_invoice_no() {
      return this.form.detail
        .filter((x) => x.si_no && !x.sr_no)
        .map((x) => x.si_no)
    },
    list_retur_no() {
      return this.form.detail.map((x) => x.sr_no)
    },
    list_daftar_kas() {
      return this.daftar_kas.filter(
        (el) => !el.rekening && el.nama.toLowerCase() !== 'kas kecil'
      )
    },
    list_kode_bank() {
      return this.daftar_kas.filter((el) => el.rekening)
    },
    disabledSave() {
      let isDisabled = true
      if (this.form.detail) {
        if (this.form.detail.length > 0) {
          let isRetur = false
          this.form.detail.forEach((el) => {
            let type = el.type ? el.type : 0
            if (el.type) type = el.type
            isRetur = type === 3
          })
          if (isRetur) {
            this.form.detail.forEach((el) => {
              let type = el.type ? el.type : 0
              if (type !== 3) {
                isDisabled = false
              }
            })
          } else {
            isDisabled = false
          }
        }
      }
      return isDisabled
      //return false
    },
    jumlahPiutang() {
      return this.form.detail.reduce(function (total, item) {
        let type = item.type ? item.type : 0
        let jumlah = item.jumlah
        if (type === 3) {
          jumlah = jumlah * -1
        }
        return total + jumlah
      }, 0)
    },
    jumlahDibayar() {
      return this.form.detail.reduce(function (total, item) {
        //let type = item.type ? item.type : 0
        let jumlah = item.jumlah_dibayar
        /*
        if (type === 3) {
          jumlah = item.jumlah - item.diskon
        }
        */
        return total + jumlah
      }, 0)
    },
    jumlahRetur() {
      return this.form.detail.reduce(function (total, item) {
        let jumlah = 0
        if (item.type === 3) {
          jumlah = item.jumlah
        }
        return total + jumlah
      }, 0)
    },
  },
  mounted() {
    const self = this
    Promise.all([this.getDaftarKas()]).then(function (result) {
      self.daftar_kas = result[0]
      self.form.kas_id = result[0].filter(
        (el) => !el.rekening && el.nama.toLowerCase() !== 'kas kecil'
      )[0].id
    })

    this.list_giro_status = [
      { value: 'outstand', label: 'OUTSTAND', disabled: this.id > 0 },
      { value: 'cair', label: 'CAIR', disabled: false },
      { value: 'batal', label: 'BATAL', disabled: false },
    ]

    if (this.id > 0) {
      this.isLoading = true
      this.pembayaranService
        .get(`/${this.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.form.sp_at = this.form.sp_at ? new Date(this.form.sp_at) : null
            this.form.giro_at = this.form.giro_at
              ? new Date(this.form.giro_at)
              : null

            this.form.giro_status_at = this.form.giro_status_at
              ? new Date(this.form.giro_status_at)
              : null
            /*
            this.form.settle_at = this.form.settle_at
              ? new Date(this.form.settle_at)
              : null
            */
            //this.isTglBayar = this.form.settle_at != null
            this.form.pelanggan = res.data.data.pelanggan
            if (res.data.data.pembayaran === 'tunai') {
              this.form.kas_id = res.data.data.kas.id
            } else {
              this.form.bank_id = res.data.data.kas.id
            }
            let detail = res.data.data.detail
            detail.forEach((el, index) => {
              el.isOld = 1
              el.type = 0
              if (el.diskon) {
                el.jumlah_dibayar_display = el.jumlah_dibayar - el.diskon
              } else {
                el.jumlah_dibayar_display = el.jumlah_dibayar
              }
              if (el.srd_id > 0) {
                el.type = 3
              }
              el.row_index = index + 1
            })
            this.form.detail = detail

            //Create New Detail
            let detailNew = []
            detail.forEach((element) => {
              if (detailNew.length === 0) {
                detailNew.push(element)
              } else {
                let isExist = false
                for (let index = 0; index < detailNew.length; index++) {
                  const data = detailNew[index]

                  if (element.si_no !== '') {
                    if (element.si_no === data.si_no) {
                      detailNew[index].jumlah =
                        detailNew[index].jumlah + element.jumlah
                      detailNew[index].jumlah_dibayar =
                        detailNew[index].jumlah_dibayar + element.jumlah_dibayar
                      detailNew[index].jumlah_dibayar_display =
                        detailNew[index].jumlah_dibayar_display +
                        element.jumlah_dibayar_display
                      isExist = true
                      break
                    }
                  }
                  if (element.kb_no !== '') {
                    if (element.kb_no === data.kb_no) {
                      // console.log('Kontra')
                      detailNew[index].jumlah =
                        detailNew[index].jumlah + element.jumlah
                      detailNew[index].jumlah_dibayar =
                        detailNew[index].jumlah_dibayar + element.jumlah_dibayar
                      detailNew[index].jumlah_dibayar_display =
                        detailNew[index].jumlah_dibayar_display +
                        element.jumlah_dibayar_display
                      isExist = true
                      break
                    }
                  }
                  if (element.sr_no !== '') {
                    if (element.sr_no === data.sr_no) {
                      // console.log('Retur')
                      detailNew[index].jumlah =
                        detailNew[index].jumlah + element.jumlah
                      detailNew[index].jumlah_dibayar =
                        detailNew[index].jumlah_dibayar + element.jumlah_dibayar
                      detailNew[index].jumlah_dibayar_display =
                        detailNew[index].jumlah_dibayar_display +
                        element.jumlah_dibayar_display
                      isExist = true
                      break
                    }
                  }
                }

                if (!isExist) {
                  detailNew.push(element)
                }
              }
            })
            this.form.detailNew = detailNew

            this.pelangganService
              .get(`/${res.data.data.pelanggan.id}`)
              .then((res) => {
                this.form.salesman = res.data.data.salesman.nama
                this.form.kode_salesman = res.data.data.salesman.kode
                this.pelangganTop = res.data.data.top
                if (!this.form.giro_at) {
                  this.form.giro_at = new Date()
                  /*
                  this.form.giro_at = this.getTanggalKembali(
                    dayjs(new Date(this.form.sp_at))
                  )
                  */
                }
              })
            this.pembayaran = this.form.pembayaran
            /* if (this.form.detail) {
              this.form.detail.forEach((el) => {
                el.detail_id = el.id
                if (el.srd_id > 0) {
                  el.detail_no = el.sr_no
                  el.detail_at = el.sr_at
                } else {
                  el.detail_no = el.kb_no
                  el.detail_at = el.kb_at
                }
              })
            } */
          }
        })
        .catch((err) => {
          errorHandler(err, 'Kontra bon', this)
        })
        .finally(() => (this.isLoading = false))
    }
  },
  methods: {
    async getDaftarKas() {
      const kasService = new DaftarKasService()
      return await kasService
        .get()
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Daftar kas', this)
        })
    },
    async add() {
      if (!this.form.pelanggan) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Kontra bon',
          detail: 'Pelanggan harus diisi terlebih dahulu.',
          life: 3000,
        })
        return
      }
      const self = this
      this.list_kontra = await this.getKontraList()
      this.list_retur = await this.getReturList()
      this.list_invoice = await this.getInvoiceList()

      if (
        this.list_kontra.length > 0 ||
        this.list_retur.length > 0 ||
        this.list_invoice.length > 0
      ) {
        self.dialogAdd = true
        self.isLoadingInvoice = false
      } else {
        this.$toast.add({
          severity: 'warn',
          summary: 'Kontra Bon/Retur',
          detail: `Kontra Bon/Retur untuk pelanggan ${this.form.pelanggan.nama} tidak ditemukan.`,
          life: 3000,
        })
        self.dialogAdd = false
        self.isLoadingInvoice = false
      }
    },
    save(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      let form = {}
      let detail = []

      this.form.detail.forEach((el) => {
        let data = {
          diskon: el.diskon,
          jumlah_dibayar: el.jumlah_dibayar,
          keterangan: !el.keterangan ? '' : el.keterangan,
        }

        if (!el.isOld) el.id = 0

        if (el.id === 0) {
          let type = 0
          if (el.type) type = el.type
          if (type === 1 && el.si_no) {
            data.si_id = el.si_id
          }
          if (type === 2 && el.kb_no) {
            data.kbd_id = el.kbd_id
          }
          if (type === 3 && el.sr_no) {
            data.srd_id = el.srd_id
            delete data.jumlah_dibayar
          }
          /*
          if (el.si_no && !el.kb_no && !el.sr_no) {
            data.si_id = el.si_id
          }
          */
        }

        if (el.id > 0) {
          data.id = el.id
        } else if (el.id === 0 && this.form.id > 0) {
          data.id = 0
        }

        // jika sudah lunas (jumlah_saldo = 0), item jangan dikirim
        // retur tidak bisa di edit, kecuali insert baru
        //if (el.jumlah_saldo > 0 || (el.sr_no && el.id === 0)) {
        //if (el.jumlah_saldo > 0 && el.jumlah > 0) {
        detail.push(data)
        //}
      })

      form.id = this.form.id ? this.form.id : 0
      if (this.form.sp_no) {
        form.sp_no = this.form.sp_no
      }

      form.sp_at = this.form.sp_at
        ? dayjs(this.form.sp_at).format('YYYY-MM-DD HH:mm:ss')
        : null
      form.pelanggan_id = this.form.pelanggan.id
      form.detail = detail
      form.pembayaran = this.form.pembayaran
      if (this.form.pembayaran === 'tunai') {
        form.kas_id = this.form.kas_id
      } else {
        form.kas_id = this.form.bank_id
      }
      form.cn = this.form.cn
      form.dn = this.form.dn
      // form.materai = this.form.materai
      //form.settle_at = null
      if (!form.cn) form.cn = 0
      if (!form.dn) form.dn = 0

      if (this.form.pembayaran === 'giro' && this.form.giro_no) {
        form.giro_no = this.form.giro_no
        // form.giro_at = this.form.giro_at
        form.giro_at = this.form.giro_at
          ? dayjs(this.form.giro_at).format('YYYY-MM-DD HH:mm:ss')
          : null

        form.giro_status = this.form.giro_status
        form.giro_status_at = this.form.giro_status_at
        form.giro_keterangan = this.form.giro_keterangan
        /*
        form.settle_at = this.form.settle_at
        if (!this.isTglBayar) {
          form.settle_at = null
        }
        if (form.settle_at) {
          form.settle_at = dayjs(form.settle_at).format('YYYY-MM-DD HH:mm:ss')
        }
        */
      }

      if (form.id === 0) {
        this.isLoadingSaveHeader = true
        this.pembayaranService
          .add(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Pembayaran Piutang',
                detail: 'Data berhasil disimpan',
                life: 3000,
              })
              this.$router.push({ name: 'PembayaranPiutang' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Pembayaran Piutang', this)
          })
          .finally(() => (this.isLoadingSaveHeader = false))
      } else {
        this.isLoadingSaveHeader = true
        form.deleted = this.deleted
        this.pembayaranService
          .update(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Pembayaran Piutang',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Pembayaran Piutang', this)
          })
          .finally(() => {
            this.deleted = []
            this.isLoadingSaveHeader = false
            this.refreshDetail()
          })
      }
    },
    refreshDetail() {
      if (this.form.id > 0) {
        this.pembayaranService.get(`/${this.id}`).then((res) => {
          if (res.data.status === 200) {
            this.form.detail = res.data.data.detail
            if (this.form.detail) {
              let detail = res.data.data.detail
              detail.forEach((el, index) => {
                el.isOld = 1
                el.type = 0
                if (el.diskon) {
                  el.jumlah_dibayar_display = el.jumlah_dibayar - el.diskon
                } else {
                  el.jumlah_dibayar_display = el.jumlah_dibayar
                }
                if (el.srd_id > 0) {
                  el.type = 3
                }
                el.row_index = index + 1
              })
              this.form.detail = detail
            }
          }
        })
      }
    },
    selectPelanggan(e) {
      this.form.salesman = e.value.salesman.nama
      this.form.kode_salesman = e.value.salesman.kode
      this.pelangganTop = e.value.top
    },
    getTanggalKembali(tanggal) {
      const top = this.pelangganTop
      const result = dayjs(tanggal.add(top, 'day'))
      return new Date(result.format(`YYYY/MM/DD`))
    },
    searchPelanggan(e) {
      this.pelangganService
        .get('?filter[nama]=' + e.query)
        .then((res) => {
          this.acPelanggan = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Pelanggan', this)
        })
    },
    async onEditInvoice(item) {
      this.formEdit = Object.assign({}, item)
      this.formEdit.kb_at = this.formEdit.kb_at
        ? new Date(this.formEdit.kb_at)
        : null
      this.formEdit.jumlah = Math.round(this.formEdit.jumlah)
      // this.formEdit.jumlah = Math.round(this.formEdit.jumlah_saldo)
      this.formEdit.jumlah_dibayar = Math.round(this.formEdit.jumlah_dibayar)
      this.dialogEdit = true
    },
    onConfirmDeletion(item) {
      this.invoice = Object.assign({}, item)
      this.dialogDelete = true
    },
    onDeleteItem() {
      //Jika Retur
      if (this.invoice.sr_no) {
        if (this.invoice.sr_no !== '') {
          //delete all child detail 
          this.form.detail.forEach((element) => {
              if (element.sr_no === this.invoice.sr_no) {
                this.deleted.push(element.id)
              }
            })

          //delete detail
          this.form.detail = this.form.detail.filter(
            (element) => element.sr_no !== this.invoice.sr_no
          )

          this.form.detail.forEach((el, index1) => {
            el.row_id = index1 + 1
          })

          //delete detail new
          this.form.detailNew = this.form.detailNew.filter(
            (element) => element.sr_no !== this.invoice.sr_no
          )

          this.form.detailNew.forEach((el, index2) => {
            el.row_id = index2 + 1
          })
        }
      } else {
        this.deleted.push(this.invoice.id)
        
        //Delete di Form Detail
        const index = this.form.detail.findIndex(
          (el) =>
            el.id === this.invoice.id &&
            el.kb_no === this.invoice.kb_no &&
            el.si_no === this.invoice.si_no
        )
        this.form.detail.splice(index, 1)

        this.form.detail.forEach((el, index) => {
          el.row_id = index + 1
        })

        //Delete di Form Detail New
        const index2 = this.form.detailNew.findIndex(
          (el) =>
            el.id === this.invoice.id &&
            el.kb_no === this.invoice.kb_no &&
            el.si_no === this.invoice.si_no
        )
        this.form.detailNew.splice(index2, 1)

        this.form.detailNew.forEach((el, index2) => {
          el.row_id = index2 + 1
        })
      }

      this.invoice = {}
      this.dialogDelete = false
      this.setJumlahPiutang()
    },
    onSaveDetail(data) {
      console.log(data.output1) // output1 data
      console.log(data.output2) // output2 data
      const output1 = data.output1
      const output2 = data.output2

      //Save All Row to form.detail
      output1.forEach((el, index) => {
        const item_at = el.item_at
        if (item_at) {
          this.form.giro_at = this.getTanggalKembali(dayjs(item_at))
        }
        if (el.jumlah_saldo > 0) {
          el.jumlah = el.jumlah_saldo
        }
        el.jumlah_dibayar = el.jumlah
        el.jumlah_dibayar_display = el.jumlah
        if (el.jumlah > 0) {
          this.form.detail.push(el)
        }
        el.row_id = index + 1
      })

      //Save Just Parent to form.detailNew
      output2.forEach((el, index) => {
        const item_at = el.item_at
        if (item_at) {
          this.form.giro_at = this.getTanggalKembali(dayjs(item_at))
        }
        if (el.jumlah_saldo > 0) {
          el.jumlah = el.jumlah_saldo
        }
        el.jumlah_dibayar = el.jumlah
        el.jumlah_dibayar_display = el.jumlah
        if (el.jumlah > 0) {
          this.form.detailNew.push(el)
        }
        el.row_id = index + 1
      })
      this.dialogAdd = false
      this.setJumlahPiutang()
    },
    onUpdateInvoice(item) {
      /*
      const index = this.form.detail.findIndex(
        (el) =>
          el.id === item.id &&
          el.kb_no === item.kb_no &&
          el.srd_id === item.srd_id
      )
      */
      const index = this.form.detail.findIndex(
        (el) => el.row_id === item.row_id
      )
      item.jumlah_dibayar = item.jumlah_dibayar - this.jumlahRetur
      this.form.detail[index] = item
      if (this.jumlahDibayar && this.jumlahRetur) {
        this.jumlah = this.jumlahDibayar - this.jumlahRetur
      } else {
        this.jumlah = 0
      }
      /*
      item.jumlah_dibayar = item.jumlah_dibayar_tmp - this.jumlahRetur
      const index = this.form.detail.findIndex(
        (el) => el.row_id === item.row_id
      )
      this.form.detail[index] = item
      this.dialogEdit = false
      console.log('form.detail', this.form.detail)
      console.log('jumlahDibayar', this.jumlahDibayar)
      console.log('jumlahPiutang', this.jumlahPiutang)
      this.jumlah = this.jumlahPiutang - this.jumlahDibayar
      */
      //this.recalcDetail()
      this.dialogEdit = false
    },
    onCloseFormKontra() {
      this.dialogAdd = false
    },
    /* onUpdateMaterai(item) {
      this.form.materai = item
    }, */
    onUpdateCn(item) {
      this.form.cn = item
    },
    onUpdateDn(item) {
      this.form.dn = item
    },
    onUpdateMaterai(item) {
      this.form.materai = item
    },
    onUpdateDetail(detail) {
      this.form.detail = detail
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.add()
      }
    },
    onInputJumlah(e) {
      let value = e.value
      if (value > this.jumlahPiutang) {
        value = this.jumlahPiutang
      }
      this.jumlah = value
    },
    async getInvoiceList() {
      let result = []
      const self = this
      self.isLoadingInvoice = true
      const invoiceService = new InvoiceService()
      const res = await invoiceService.get(
        `?filter[pelanggan_id]=${this.form.pelanggan.id}&filter[status]=done&filter[has_bon]=0` // &filter[status_lunas]=0
      )
      if (res) {
        if (res.data.data) {
          if (res.data.data.length > 0) {
            let tmpResult = []
            tmpResult = res.data.data
            tmpResult.forEach((el) => {
              el.si_id = el.id
              el.item_at = el.si_at
              el.type = 1
              if (el.jumlah_saldo > 0) {
                el.jumlah_saldo_total = el.jumlah_saldo
                result.push(el)
              }
            })
          }
        }
      }
      self.isLoadingInvoice = false
      return result
    },
    async getKontraList() {
      let result = []
      const self = this
      self.isLoadingInvoice = true
      const kontraService = new KontraService()
      const res = await kontraService.get(
        `?filter[pelanggan_id]=${this.form.pelanggan.id}` // &filter[status_lunas]=0
      )
      if (res) {
        if (res.data.data) {
          if (res.data.data.length > 0) {
            let tmpResult = []
            tmpResult = res.data.data
            tmpResult.forEach((el) => {
              const detail = el.detail
              el.item_at = el.kb_at
              el.type = 2
              if (detail.length > 0) {
                detail.forEach((kb) => {
                  let item = Object.assign({}, el)
                  item.id = kb.id
                  item.kbd_id = kb.id
                  item.kb_no = el.kb_no
                  item.si_no = kb.si_no
                  item.sr_no = null
                  item.jumlah = kb.jumlah
                  item.diskon = 0
                  item.jumlah_dibayar = kb.jumlah
                  item.jumlah_saldo = kb.jumlah_saldo
                  if (kb.jumlah_saldo > 0) {
                    result.push(item)
                  }
                })
              }
            })
          }
        }
      }
      self.isLoadingInvoice = false
      return result
    },
    async getReturList() {
      let result = []
      const self = this
      self.isLoadingInvoice = true
      const returService = new ReturService()
      const res = await returService.get(
        `?filter[pelanggan_id]=${this.form.pelanggan.id}&filter[status_lunas]=0&filter[status]=sent`
      )
      if (res) {
        if (res.data.data) {
          if (res.data.data.length > 0) {
            let tmpResult = []
            tmpResult = res.data.data
            tmpResult.forEach((el) => {
              el.item_at = el.sr_at
              el.jumlah_saldo = el.jumlah // display sisa
              el.type = 3
              const obat = el.obat
              el.jumlah_saldo_total = el.jumlah_saldo
              if (obat.length > 0) {
                /*
                el.si_no = obat[0].si_no
                el.si_id = obat[0].si_id
                el.srd_id = obat[0].sr_id
                */
                /*
                obat.forEach((o) => {
                  el.jumlah_saldo = o.jumlah_saldo
                })
                */
                //if (el.jumlah_saldo && el.jumlah_saldo > 0) {
                result.push(el)
                //}
              }
            })
          }
        }
      }
      self.isLoadingInvoice = false
      return result
    },
    setJumlahPiutang() {
          this.jumlah = this.jumlahPiutang
      // if (this.jumlah > this.jumlahPiutang) {
      //   if (this.jumlahPiutang) {
      //     this.jumlah = this.jumlahPiutang
      //   } else {
      //     this.jumlah = 0
      //   }
      // } else if (this.jumlah === 0) {
      //   if (this.jumlahPiutang) {
      //     this.jumlah = this.jumlahPiutang
      //   } else {
      //     this.jumlah = 0
      //   }
      // } else {
      //   this.jumlah = 0
      // }
    },
    recalcDetail() {
      let value = 0
      if (this.jumlahRetur) {
        value = this.jumlah + this.jumlahRetur
      } else {
        value = this.jumlah
      }
      this.form.detail.forEach((item) => {
        let saldo = 0
        if (item.diskon) {
          saldo = item.jumlah - item.diskon
        } else {
          saldo = item.jumlah
        }
        if (item.type !== 3) {
          item.jumlah_dibayar = 0
          if (value >= saldo) {
            item.jumlah_dibayar = saldo
          } else {
            if (value > 0) {
              item.jumlah_dibayar = value
            }
          }
          value -= saldo
        } else {
          item.jumlah_dibayar = saldo
        }
      })
    },
  },
  watch: {
    jumlah() {
      this.recalcDetail()
    },
    pembayaran() {
      this.form.pembayaran = this.pembayaran
      if (this.form.pembayaran !== 'giro') {
        if (!this.form.giro_no) {
          this.form.giro_no = '-'
          this.form.giro_at = new Date()
        }
      } else {
        if (this.form.giro_no === '-') {
          this.form.giro_no = ''
          this.form.giro_at = null
        }
      }
    },
  },
  validations() {
    return {
      form: {
        sp_at: {
          required: helpers.withMessage('Tanggal harus diisi.', required),
        },
        pelanggan: {
          required: helpers.withMessage('Pelanggan harus diisi.', required),
        },
        kas_id: {
          required: helpers.withMessage(
            'Kode Kas harus diisi.',
            requiredIf(this.form.pembayaran == 'tunai')
          ),
        },
        bank_id: {
          required: helpers.withMessage(
            'Kode Bank harus diisi.',
            requiredIf(this.form.pembayaran == 'transfer')
          ),
        },
        giro_no: {
          required: helpers.withMessage(
            'No. Giro harus diisi.',
            requiredIf(this.form.pembayaran == 'giro')
          ),
        },
        giro_at: {
          required: helpers.withMessage(
            'Tanggal jatuh tempo harus diisi.',
            requiredIf(this.form.pembayaran == 'giro')
          ),
        },
      },
    }
  },
}
</script>
