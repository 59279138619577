<template>
  <div>
    <form @submit.prevent="save(!v$.$invalid)">
      <div class="card bg-white">
        <div class="flex justify-content-end mb-2">
          <Button
            :disabled="disabledSaveButton"
            :loading="isLoadingSaveHeader"
            icon="pi pi-save"
            class="mr-2"
            label="Simpan"
            type="submit"
          />
          <Button
            :loading="isLoadingBarang"
            :disabled="old_status_value !== 'draft'"
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Barang"
            @click="add"
          />
          <Button
            :disabled="old_status_value !== 'draft'"
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Materai"
            @click="dialogMaterai = true"
          />
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'SalesInvoice' })"
          />
        </div>
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="formgrid grid">
              <div class="field col-12 md:col-4">
                <label>Tanggal</label>
                <Calendar
                  v-model="form.si_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>No Faktur</label>
                <InputText v-model="form.si_no" class="w-full" disabled />
              </div>
              <div class="field col-12 md:col-4">
                <label>Materai</label>
                <InputNumber
                  v-model="form.materai"
                  :minFractionDigits="0"
                  mode="currency"
                  currency="IDR"
                  locale="id-ID"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>Pembayaran</label>
                <SelectButton
                  v-model="form.pembayaran"
                  :options="[
                    { name: 'Tunai', value: 'tunai' },
                    { name: 'Kredit', value: 'kredit' },
                  ]"
                  optionValue="value"
                  optionLabel="name"
                  disabled
                />
              </div>
              <div class="field col-12 md:col-8">
                <label
                  :class="{
                    'p-error': v$.form.pelanggan.$invalid && submitted,
                  }"
                  >Pelanggan</label
                >
                <AutoComplete
                  v-model="form.pelanggan"
                  :suggestions="acPelanggan"
                  :min-length="2"
                  :class="{
                    'p-invalid': v$.form.pelanggan.$invalid && submitted,
                  }"
                  class="w-full"
                  input-class="w-full"
                  field="nama"
                  @complete="searchPelanggan($event)"
                  @item-select="selectPelanggan"
                >
                  <template #item="slotProps">
                    <div>
                      <strong
                        >{{ slotProps.item.kode }} -
                        {{ slotProps.item.nama }}</strong
                      >
                    </div>
                    <div>Alamat: {{ slotProps.item.alamat }}</div>
                    <div>Salesman: {{ slotProps.item.salesman.nama }}</div>
                  </template>
                </AutoComplete>
                <small
                  v-if="
                    (v$.form.pelanggan.$invalid && submitted) ||
                    v$.form.pelanggan.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.pelanggan.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-4">
                <label>T.O.P</label>
                <InputText v-model="form.top" class="w-full" disabled />
              </div>
              <div class="field col-12 md:col-4">
                <label>Tgl jatuh tempo</label>
                <InputText
                  v-model="form.tgl_jatuh_tempo"
                  class="w-full"
                  disabled
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>Salesman</label>
                <Dropdown
                  v-model="form.salesman"
                  :options="list_salesman"
                  optionValue="id"
                  optionLabel="nama"
                  class="w-full"
                  disabled
                />
              </div>
              <div class="field col-12">
                <label>Keterangan</label>
                <InputText v-model="form.keterangan" class="w-full" />
              </div>
              <div class="field col-12">
                <label>Alamat pengiriman</label>
                <InputText v-model="form.pengiriman" class="w-full" />
              </div>
              <!--
              <div v-if="showWarningMinimum" class="col-12">
                <Message severity="error" :closable="false"
                  >Total faktur dibawah jumlah minimun
                  {{ formatCurrency(jumlah_minimum) }}</Message
                >
              </div>
              -->
            </div>
          </div>
          <div class="col-12 md:col-3">
            <div class="formgrid grid">
              <div class="field col-12">
                <label
                  :class="{
                    'p-error': v$.form.golongan.$invalid && submitted,
                  }"
                  >Golongan obat</label
                >
                <Dropdown
                  v-model="form.golongan"
                  :options="list_golongan_obat"
                  :disabled="form.id > 0"
                  :class="{
                    'p-invalid': v$.form.golongan.$invalid && submitted,
                  }"
                  optionValue="id"
                  optionLabel="nama"
                  class="w-full"
                />
                <small
                  v-if="
                    (v$.form.golongan.$invalid && submitted) ||
                    v$.form.golongan.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.golongan.required.$message }}</small
                >
              </div>
              <div class="field col-12">
                <label>Status</label>
                <Dropdown
                  v-model="form.status"
                  :options="list_status"
                  :disabled="form.id === 0"
                  optionValue="value"
                  optionLabel="label"
                  optionDisabled="disabled"
                  class="w-full"
                  input-class="w-full"
                />
              </div>
              <div class="field col-12 mt-2">
                <div class="grid">
                  <div class="field col-10 mr-0 pr-0">
                    <label>Kurir</label>
                    <AutoComplete
                      v-model="form.kurir"
                      :suggestions="acKurir"
                      :min-length="2"
                      class="w-full"
                      input-class="w-full"
                      field="nama"
                      @complete="searchKurir($event)"
                    >
                      <template #item="slotProps">
                        <div>
                          <strong>{{ slotProps.item.nama }}</strong>
                        </div>
                        <div>Nopol: {{ slotProps.item.nopol }}</div>
                      </template>
                    </AutoComplete>
                  </div>
                  <div class="field col-2 ml-0 pl-0">
                    <label style="width: 100%">&nbsp;</label>
                    <Button
                      icon="pi pi-plus"
                      class="p-button-outlined w-full"
                      @click="dialogKurir = true"
                    />
                  </div>
                </div>
              </div>
              <div class="field col-12 -mt-2">
                <div class="grid">
                  <div class="field col-12">
                    <label>Waktu Pengiriman</label>
                    <Calendar
                      v-model="form.kurir_at"
                      dateFormat="dd/mm/yy"
                      class="w-full"
                      :showTime="true"
                      hourFormat="24"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <grid-sales-invoice-detail
          :items="form.obat"
          :loading="isLoading"
          :materai="form.materai"
          :total="form.obat.length"
          :show-button="old_status_value === 'draft'"
          :show-edit="
            old_status_value === 'pen1' || old_status_value === 'pen2'
          "
          @edit="onEditData"
          @delete="onConfirmDeletion"
          @total="onGetTotal"
        />
        <!--
          :show-button="old_status_value === 'draft'"
          :show-edit="old_status_value === 'pen1' || old_status_value === 'pen2'"
        -->
      </div>
    </form>
    <Dialog
      header="Tambah Barang"
      v-model:visible="dialogAdd"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
      maximizable
    >
      <form-sales-invoice-barang
        :sales-order="list_sales_order"
        :exclude-list="list_produk"
        @close="onCloseFormBarang"
        @save="onSaveBarang"
      />
    </Dialog>
    <Dialog
      header="Hapus sales order dari faktur ?"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
    >
      <div>
        <span
          >Sales order <strong>{{ so.nama_obat }}</strong> akan dihapus dari
          faktur. ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogHapus = false"
          class="p-button-text mr-2"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeleteSO"
        />
      </template>
    </Dialog>
    <Dialog
      header="Tambah materai"
      v-model:visible="dialogMaterai"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      :closable="false"
      modal
    >
      <div>
        <p>
          Gunakan materai untuk mengijinkan transaksi diatas
          {{ formatCurrency(limit_transaksi) }}
        </p>
        <InputNumber
          v-model="materai"
          :minFractionDigits="0"
          mode="currency"
          currency="IDR"
          locale="id-ID"
          class="w-full"
          input-class="w-full"
          autofocus
        />
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogMaterai = false"
          class="p-button-text p-button-secondary mr-2"
        />
        <Button
          label="Tambah"
          icon="pi pi-check"
          class="p-button-text"
          @click="addMaterai"
        />
      </template>
    </Dialog>
    <Dialog
      header="Tambah Kurir"
      v-model:visible="dialogKurir"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      :closable="false"
      modal
    >
      <div>
        <div class="formgrid grid">
          <div class="field col-12">
            <label>Nama</label>
            <InputText v-model="kurir.nama" class="w-full" />
          </div>
          <div class="field col-12">
            <label>Nopol</label>
            <InputText v-model="kurir.nopol" class="w-full" />
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogKurir = false"
          class="p-button-text p-button-secondary mr-2"
        />
        <Button
          label="Tambah"
          icon="pi pi-check"
          class="p-button-text"
          @click="addKurir"
        />
      </template>
    </Dialog>
    <Dialog
      header="Edit harga"
      v-model:visible="dialogEdit"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
    >
      <form-harga :item="formEdit" @save="onUpdateHarga" />
    </Dialog>
    <hotkey :shortcuts="['D', 'S']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import FormSalesInvoiceBarang from '@/components/penjualan/FormSalesInvoiceBarang'
import FormHarga from '@/components/penjualan/FormHarga'
import GridSalesInvoiceDetail from '@/components/penjualan/GridSalesInvoiceDetail'
import PelangganService from '@/services/PelangganService'
import GolonganObatService from '@/services/GolonganObatService'
import SalesOrderService from '@/services/SalesOrderService'
import SalesInvoiceService from '@/services/SalesInvoiceService'
import SalesmanService from '@/services/SalesmanService'
import KurirService from '@/services/KurirService'
// import SettingService from '@/services/SettingService'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { formatCurrency } from '@/helpers'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    GridSalesInvoiceDetail,
    FormSalesInvoiceBarang,
    FormHarga,
    Hotkey,
  },
  created() {
    this.pelangganService = new PelangganService()
    this.salesInvoiceService = new SalesInvoiceService()
    this.kurirService = new KurirService()
    /*
    const settingService = new SettingService()
    settingService.get('?filter[key]=si_limit').then((res) => {
      this.limit_transaksi = res.data.data.value
    })
    */
  },
  data() {
    return {
      limit_transaksi: 0,
      list_sales_order: null,
      list_salesman: null,
      materai: 10000,
      submitted: false,
      dialogAdd: false,
      dialogHapus: false,
      dialogMaterai: false,
      dialogKurir: false,
      dialogEdit: false,
      isLoading: false,
      isLoadingBarang: false,
      isLoadingSaveHeader: false,
      acPelanggan: [],
      acKurir: [],
      pelangganService: null,
      salesInvoiceService: null,
      kurirService: null,
      so: null,
      deleted: [],
      list_golongan_obat: null,
      kurir: {
        nama: '',
        nopol: '',
      },
      form: {
        id: 0,
        si_at: new Date(),
        kurir_at: null,
        si_no: '',
        materai: 0,
        top: 0,
        tgl_jatuh_tempo: null,
        golongan: null,
        salesman: null,
        pelanggan: null,
        pembayaran: null,
        kurir: null,
        status: 'draft',
        obat: [],
      },
      formEdit: {},
      disabledSaveButton: false,
      showWarningMinimum: false,
      kota: '',
      /*
      jumlah_minimum: 0,
      ruleMinimum: [
        { kota: 'cianjur', jumlah: 100000 }, // dalam kota
        { kota: '', jumlah: 150000 }, // luar kota
      ],
      */
      old_status_value: 'draft',
    }
  },
  mounted() {
    const self = this
    Promise.all([this.getGolonganObat(), this.getSalesman()]).then(function (
      result
    ) {
      self.list_golongan_obat = result[0]
      self.list_salesman = result[1]
    })
    this.loadData()
  },
  computed: {
    list_produk() {
      return this.form.obat.map((x) => x.id)
    },
    list_status() {
      const list = [
        { value: 'draft', label: 'Draft', level: 0 },
        { value: 'open', label: 'Open', level: 1 },
        { value: 'pen1', label: 'Pending 1', level: 2 },
        { value: 'pen2', label: 'Pending 2', level: 3 },
        { value: 'done', label: 'Done', level: 4 },
      ]
      let old_status_level = 0
      if (this.old_status_value) {
        const status = list.find((e) => e.value === this.old_status_value)
        if (status) old_status_level = status.level
      }
      return list.map((status) => ({
        ...status,
        disabled: old_status_level > status.level,
      }))
    },
  },
  methods: {
    /*
    getListStatus() {
      let status = []
      switch (this.old_status_value) {
        case 'draft':
          status = ['draft', 'open']
          //status = ['draft', 'open', 'pen1', 'pen2', 'done']
          break
        case 'open':
          status = ['open', 'pen1', 'pen2', 'done']
          break
        case 'pen1':
          status = ['pen1', 'pen2', 'done']
          break
        case 'pen2':
          status = ['pen2', 'done']
          break
        case 'done':
          status = ['done']
          break
        default:
          status.push('draft')
          break
      }
      this.list_status = status
    },
    */
    async getGolonganObat() {
      const golonganObatService = new GolonganObatService()
      return await golonganObatService
        .get('?sort=nama')
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data golongan obat', this)
        })
    },
    async getSalesman() {
      const salesmanService = new SalesmanService()
      return await salesmanService
        .get('?sort=nama')
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data salesman', this)
        })
    },
    add() {
      let golongan = null

      if (!this.form.pelanggan || !this.form.golongan) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Faktur penjualan',
          detail: 'Pelanggan & golongan obat harus diisi terlebih dahulu.',
          life: 3000,
        })
        return
      }

      const obat = this.list_golongan_obat.find(
        (e) => e.id === this.form.golongan
      )

      if (obat) {
        golongan = obat.nama
      }

      const salesOrderService = new SalesOrderService()
      const self = this
      self.isLoadingBarang = true
      salesOrderService
        .get(
          `?filter[golongan]=${golongan}&filter[pelanggan_id]=${this.form.pelanggan.id}&filter[status]=pick&filter[has_invoice]=0`
        )
        .then((res) => {
          if (!res.data.data || res.data.data.length === 0) {
            this.$toast.add({
              severity: 'warn',
              summary: 'Sales Order',
              detail: `Sales order untuk pelanggan ${this.form.pelanggan.nama} tidak ditemukan.`,
              life: 3000,
            })
            self.isLoadingBarang = false
          } else {
            let data = res.data.data
            data.forEach((item) => {
              let obat = item.obat
              obat.forEach((el) => {
                el.diskon_rp = el.diskon > 0 ? el.harga * (el.diskon / 100) : 0
                el.quantity_tkcl = el.quantity > 0 ? el.quantity_tkcl : 0
                el.jumlah = el.quantity_tkcl ? el.harga * el.quantity_tkcl : 0
              })
            })
            self.list_sales_order = data
            self.dialogAdd = true
            self.isLoadingBarang = false
          }
        })
        .catch(() => {
          self.isLoadingBarang = false
        })
    },
    onConfirmDeletion(item) {
      this.so = Object.assign({}, item)
      this.dialogHapus = true
    },
    onGetTotal() {
      /*
      const query = this.kota.includes('cianjur') ? 'cianjur' : ''
      const result = this.ruleMinimum.find((rule) => rule.kota === query)
      if (result) {
        this.jumlah_minimum = result.jumlah
        if (total < result.jumlah) {
          this.showWarningMinimum = true
          this.disabledSaveButton = true
        } else {
          this.showWarningMinimum = false
          this.disabledSaveButton = false
        }
      }
      */
    },
    onDeleteSO() {
      if (this.so.id !== 0) {
        this.deleted.push(this.so.id)
      }
      const index = this.form.obat.findIndex(
        (el) => el.id === this.so.id && el.obat_id === this.so.obat_id
      )
      this.form.obat.splice(index, 1)
      this.productIndex = -1
      this.so = {}
      this.dialogHapus = false
    },
    onEditData(item) {
      item.jumlah = item.harga * item.quantity_tkcl
      this.formEdit = Object.assign({}, item)
      this.dialogEdit = true
    },
    onUpdateHarga(item) {
      item.diskon_rp = item.diskon
        ? item.harga * (item.diskon / 100)
        : item.diskon_rp
      const index = this.form.obat.findIndex(
        (el) =>
          el.id === item.id &&
          el.obat_id === item.obat_id &&
          el.so_id === item.so_id
      )
      this.form.obat[index] = item
      this.dialogEdit = false
    },
    save(isFormValid) {
      this.submitted = true
      let isContinue = true
      if (isFormValid) {
        if (this.form.kurir_at != null) {
          if (this.form.kurir) {
            if (this.form.kurir.id === 0) {
              this.form.kurir = null
              isContinue = false
            }
          } else {
            isContinue = false
          }
        }
      }

      if (!isContinue) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Faktur penjualan',
          detail: 'Kurir harus diisi terlebih dahulu karena waktu pengiriman terisi.',
          life: 3000,
        })
        return
      }

      if (!isFormValid) {
        return
      }

      let form = {}

      let obat = []
      this.form.obat.forEach((el) => {
        if (el.sod_id) {
          let oObat = {
            id: el.id,
            keterangan: el.keterangan,
          }
          if (this.form.status === 'pen1' || this.form.status === 'pen2') {
            oObat = {
              ...oObat,
              harga: el.harga,
              diskon: el.diskon,
              diskon_harga: el.diskon_rp,
            }
          }
          obat.push(oObat)
        } else {
          let oObat = {
            id: 0,
            sod_id: el.id,
            keterangan: el.keterangan,
          }
          if (this.form.status === 'pen1' || this.form.status === 'pen2') {
            oObat = {
              ...oObat,
              harga: el.harga,
              diskon: el.diskon,
              diskon_harga: el.diskon_rp,
            }
          }
          obat.push(oObat)
        }
      })

      form.id = this.form.id
      form.golongan_id = this.form.golongan
      form.salesman_id = this.form.salesman
      form.pelanggan_id = this.form.pelanggan.id
      form.kurir_id = this.form.kurir ? this.form.kurir.id : 0
      form.status = this.form.status
      form.pengiriman = this.form.pengiriman
      form.materai = this.form.materai
      form.si_at = this.form.si_at
        ? dayjs(this.form.si_at).format('YYYY-MM-DD HH:mm:ss')
        : null
      form.kurir_at = this.form.kurir_at
        ? dayjs(new Date(this.form.kurir_at)).format('YYYY-MM-DD HH:mm:ss')
        : null
      form.obat = obat

      if (this.form.si_no) {
        form.si_no = this.form.si_no
      }
      if (this.form.keterangan) {
        form.keterangan = this.form.keterangan
      }

      /*
      const total = this.form.obat.reduce(function (total, item) {
        return total + item.harga_total
      }, 0)
      if (form.materai === 0 && total > this.limit_transaksi) {
        this.$toast.add({
          severity: 'error',
          summary: 'Faktur penjualan',
          detail: 'Jumlah invoice melebihi Rp 5.000.000 !',
          life: 3000,
        })
        return
      }
      */

      if (this.form.id === 0) {
        this.isLoadingSaveHeader = true
        this.salesInvoiceService
          .add(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Faktur penjualan',
                detail: 'Data berhasil disimpan',
                life: 3000,
              })
              this.$router.push({ name: 'SalesInvoice' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Faktur Penjualan', this)
          })
          .finally(() => {
            this.isLoadingSaveHeader = false
            this.loadData()
          })
      } else {
        this.isLoadingSaveHeader = true
        form.deleted = this.deleted
        this.salesInvoiceService
          .update(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Faktur Penjualan',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Faktur Penjualan', this)
          })
          .finally(() => {
            this.isLoadingSaveHeader = false
            this.loadData()
            this.deleted = []
          })
      }
    },
    onSaveBarang(item) {
      item.forEach((el) => {
        this.form.obat.push(el)
      })
      this.dialogAdd = false
    },
    onCloseFormBarang() {
      this.dialogAdd = false
    },
    onTriggerHotkey(payload) {
      switch (payload.keyString) {
        case 'D':
          this.add()
          break
        case 'S':
          this.save(!this.v$.$invalid)
          break
      }
    },
    searchPelanggan(e) {
      this.pelangganService
        .get('?filter[nama]=' + e.query + '&filter[status]=aktif')
        .then((res) => {
          setTimeout(() => {
            this.acPelanggan = res.data.data
          }, 1000)
        })
        .catch((err) => {
          errorHandler(err, 'Data Pelanggan', this)
        })
    },
    selectPelanggan(e) {
      this.form.top = e.value.top
      this.form.salesman = e.value.salesman.id
      this.form.tgl_jatuh_tempo = dayjs(this.form.si_at)
        .add(e.value.top, 'day')
        .format('DD/MM/YYYY')
      this.form.pembayaran = e.value.status_pembayaran
      this.form.pengiriman = e.value.pengiriman_alamat
      this.kota = e.value.kota.toLowerCase()
      this.form.sia_validity = dayjs(e.value.sia_validity).format('DD/MM/YYYY')
      this.form.sipa_validity = dayjs(e.value.sipa_validity).format(
        'DD/MM/YYYY'
      )
    },
    searchKurir(e) {
      this.kurirService
        .get('?filter[nama]=' + e.query)
        .then((res) => {
          this.acKurir = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Kurir', this)
        })
    },
    addKurir() {
      this.kurirService
        .add(this.kurir)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Tambah Kurir',
              detail: 'Data berhasil disimpan',
              life: 3000,
            })
            this.kurir.id = res.data.data
            this.form.kurir = this.kurir
          }
        })
        .catch((err) => {
          errorHandler(err, 'Tambah Kurir', this)
        })
        .finally(() => {
          this.dialogKurir = false
          this.kurir = { id: 0, nama: '', nopol: '' }
        })
    },
    addMaterai() {
      this.form.materai = this.materai
      this.dialogMaterai = false
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    loadData(){
      if (this.id > 0) {
        this.isLoading = true
        this.salesInvoiceService
          .get(`/${this.id}`)
          .then((res) => {
            if (res.data.status === 200) {
              this.form = res.data.data
              this.form.si_at = this.form.si_at ? new Date(this.form.si_at) : null
              this.form.golongan = res.data.data.golongan.id
              this.form.salesman = res.data.data.salesman.id
              this.form.tgl_jatuh_tempo = dayjs(this.form.si_at)
                .add(this.form.top, 'day')
                .format('DD/MM/YYYY')
              this.old_status_value = this.form.status

              if (res.data.data.kurir_at) {
                //this.form.kurir_at = dayjs(this.form.kurir_at)
                this.form.kurir_at = new Date(this.form.kurir_at)
              }

              let obat = res.data.data.obat
              obat.forEach((el) => {
                el.diskon_rp = el.diskon > 0 ? el.harga * (el.diskon / 100) : 0
                //el.jumlah = (el.harga - el.diskon_rp) * el.quantity_tkcl
              })
              this.form.obat = obat
              console.log('form', this.form)
            }
          })
          .catch((err) => {
            errorHandler(err, 'Sales Order', this)
          })
          .finally(() => (this.isLoading = false))
      }
    }
  },
  validations() {
    return {
      form: {
        pelanggan: {
          required: helpers.withMessage('Pelanggan harus diisi.', required),
        },
        golongan: {
          required: helpers.withMessage('Golongan obat harus diisi.', required),
        },
        /*
        kurir: {
          required: helpers.withMessage('Kurir harus diisi.', required),
        },
        */
      },
    }
  },
}
</script>
