<template>
  <div>
    <div class="formgrid grid">
      <div class="field col-12">
        <DataTable
          v-model:selection="selected"
          v-model:filters="filters"
          :value="list_items"
          :globalFilterFields="['item_no', 'kb_no', 'sr_no', 'si_no', 'jumlah']"
          responsiveLayout="scroll"
        >
          <template #header>
            <div class="flex justify-content-end">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Filter invoice"
                />
              </span>
            </div>
          </template>
          <Column selectionMode="multiple" headerStyle="width: 3em" />
          <Column field="kb_no" header="No Kontra" style="min-width: 10rem" />
          <Column field="si_no" header="No Invoice" style="min-width: 10rem" />
          <Column field="sr_no" header="No Retur" style="min-width: 10rem" />
          <Column field="item_at" header="Tgl Bukti" style="min-width: 8rem">
            <template #body="{ data }">
              {{ formatDate(data.item_at) }}
            </template>
          </Column>
          <Column field="jumlah" header="Total" style="min-width: 10rem">
            <template #body="{ data }">
              {{ formatCurrency(data.jumlah) }}
            </template>
          </Column>
          <Column field="jumlah_saldo" header="Sisa" style="min-width: 10rem">
            <template #body="{ data }">
              {{ formatCurrency(data.jumlah_saldo) }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrency, formatDate } from '@/helpers'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  props: {
    kontra: {
      type: Array,
      default() {
        return []
      },
    },
    retur: {
      type: Array,
      default() {
        return []
      },
    },
    invoice: {
      type: Array,
      default() {
        return []
      },
    },
    kontraExcludeList: {
      type: Array,
      default() {
        return []
      },
    },
    returExcludeList: {
      type: Array,
      default() {
        return []
      },
    },
    invoiceExcludeList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      selected: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        kb_no: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        kb_at: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        jumlah: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
      disabledSave: true,
    }
  },
  watch: {
    selected(val) {
      this.disabledSave = !(val && val.length > 0)
    },
  },
  computed: {
    list_items() {
      const result_kontra = this.kontra.slice()
      const result_invoice = this.invoice.slice()
      const result_retur = this.retur.slice()

      const filtered_invoice = result_invoice
        .filter((item) => {
          return this.invoiceExcludeList.indexOf(item.si_no) === -1
        })
        .sort(
          (a, b) =>
            new Date(a.item_at).getTime() - new Date(b.item_at).getTime()
        )
      const filtered_kontra = result_kontra
        .filter((item) => {
          return this.kontraExcludeList.indexOf(item.kb_no) === -1
        })
        .sort(
          (a, b) =>
            new Date(a.item_at).getTime() - new Date(b.item_at).getTime()
        )
      const filtered_retur = result_retur
        .filter((item) => {
          return this.returExcludeList.indexOf(item.sr_no) === -1
        })
        .sort(
          (a, b) =>
            new Date(a.item_at).getTime() - new Date(b.item_at).getTime()
        )
      const first = filtered_invoice
        .concat(filtered_kontra)
        .sort(
          (a, b) =>
            new Date(a.item_at).getTime() - new Date(b.item_at).getTime()
        )
      return first.concat(filtered_retur)
    },
  },
  methods: {
    add() {
      if (this.selected != null) {
        if (this.selected.length > 0) {
          const selected = this.selected.slice()
          let output1 = []
          let output2 = []
          selected.forEach((el) => {
            let item = {}
            el.diskon = 0
            const type = el.type
            switch (type) {
              case 1: // invoice
                item.id = 0
                item.si_id = el.id
                item.item_at = el.si_at
                item.kb_no = null
                item.si_no = el.si_no
                item.sr_no = null
                item.jumlah = el.jumlah
                item.diskon = 0
                item.jumlah_dibayar = el.jumlah_dibayar
                item.jumlah_saldo = el.jumlah_saldo
                item.type = el.type
                item.status_lunas = el.status_lunas || false
                if (item.status_lunas === false) {
                  output1.push(item)
                  output2.push(item)
                }
                break
              case 2: // kontrabon
                item.id = 0
                item.kbd_id = el.id
                item.item_at = el.kb_at
                item.kb_no = el.kb_no
                item.si_no = el.si_no
                item.sr_no = null
                item.jumlah = el.jumlah
                item.diskon = 0
                item.jumlah_dibayar = el.jumlah
                item.jumlah_saldo = el.jumlah_saldo
                item.type = el.type
                item.status_lunas = el.status_lunas || false
                if (item.status_lunas === false) {
                  output1.push(item)
                  output2.push(item)
                }
                break
              case 3: // retur
                el.obat.forEach((retur) => {
                  let item = {}
                  item.id = 0
                  item.srd_id = retur.id
                  item.item_at = el.sr_at
                  item.kb_no = null
                  item.si_no = retur.si_no
                  item.sr_no = el.sr_no
                  item.jumlah = retur.harga_total
                  item.diskon = 0
                  item.jumlah_dibayar = retur.harga_total
                  item.jumlah_saldo = retur.harga_total
                  item.type = el.type
                  item.status_lunas = el.status_lunas || false
                  if (item.status_lunas === false) {
                    output1.push(item)
                  }
                })

                item = {}
                item.id = 0
                item.srd_id = el.id
                item.item_at = el.sr_at
                item.kb_no = null
                item.si_no = null
                item.sr_no = el.sr_no
                item.jumlah = el.jumlah
                item.diskon = 0
                item.jumlah_dibayar = el.jumlah
                item.jumlah_saldo = el.jumlah_saldo
                item.type = el.type
                item.status_lunas = false
                output2.push(item)
                break
            }
          })
          this.$emit('save', {
            output1: output1,
            output2: output2,
          })
        }
      }
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      value = value ? value : 0
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
