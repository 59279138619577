<template>
  <div>
    <DataTable
      v-model:filters="filters"
      v-model:expandedRows="expandedRows"
      :value="items"
      :loading="loading"
      :rows="10"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      filterDisplay="row"
      dataKey="id"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      class="p-datatable-sm"
      row-hover
      paginator
      scrollable
      lazy
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column style="min-width: 3rem; flex: initial" header="" expander />
      <Column
        field="sp_no"
        header="No. Bukti"
        style="min-width: 12rem"
        headerStyle="min-width: 12rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        dataType="date"
        field="sp_at"
        filter-field="payment_between"
        header="Tanggal"
        style="min-width: 12rem"
        headerStyle="min-width: 12rem"
        sortable
      >
        <template #body="{ data }">
          {{ formatDate(data.sp_at) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <CalendarFilter
            v-model="filterModel.value"
            @select="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="pelanggan.nama"
        sort-field="pelanggan"
        filter-field="pelanggan"
        header="Pelanggan"
        style="min-width: 15rem"
        headerStyle="min-width: 15rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column field="pembayaran" header="Pembayaran" style="min-width: 15rem">
        <template #body="{ data }">
          <div>
            {{ data.pembayaran.toUpperCase() }}
            <span v-if="data.pembayaran === 'giro'">
              , No: {{ data.giro_no }}, Tgl. Jatuh Tempo:
              {{ formatDate(data.giro_at) }}
            </span>
          </div>
        </template>
      </Column>
      <Column field="kas.nama" header="Kas/Bank" style="min-width: 10rem">
        <template #body="{ data }">
          <span v-if="data.kas.rekening">
            {{ data.kas.nama }}&nbsp;({{ data.kas.rekening }})
          </span>
          <span v-else>{{ data.kas.nama }}</span>
        </template>
      </Column>
      <!--<Column
        field="giro_status"
        filter-field="giro_status"
        header="Status"
        style="min-width: 6rem"
        headerStyle="min-width: 6rem"
      >
        <template #filter="{ filterModel, filterCallback }">
          <div class="field-checkbox">
            <Checkbox
              id="status-cair"
              v-model="filterModel.value"
              :binary="true"
              @change="filterCallback()"
            />
            <label for="status-cair">Cair</label>
          </div>
        </template>
        <template #body="{ data }">
          {{ formatDate(data.giro_status_at) }}
        </template>
      </Column>-->
      <Column
        field="jumlah - jumlah_retur"
        header="Total"
        type="numeric"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah - data.jumlah_retur) }}
        </template>
      </Column>
      <Column
        field="jumlah_total"
        header="Dibayar"
        type="numeric"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah_total) }}
        </template>
      </Column>
      <!--<Column
        field="jumlah_diskon"
        header="Diskon"
        type="numeric"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah_diskon) }}
        </template>
      </Column>-->
      <Column
        field="jumlah_saldo"
        header="Saldo"
        type="numeric"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah_saldo) }}
        </template>
      </Column>
      <Column
        style="
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
          min-width: 200px;
        "
      >
        <template #body="{ data }">
          <Button
            type="button"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-secondary p-button-text"
            @click="$emit('edit', data)"
          />
          <Button
            type="button"
            icon="pi pi-print"
            class="p-button-outlined p-button-secondary p-button-text mr-2"
            @click="$emit('print', data)"
          />
          <!-- <Button
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-danger p-button-text"
            @click="$emit('delete', data)"
          /> -->
        </template>
      </Column>
      <template #expansion="slotProps">
        <grid-pembayaran-kontra :items="slotProps.data.detailNew" />
      </template>
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency, formatDate } from '@/helpers'
import CalendarFilter from '@/components/CalendarFilter'
import GridPembayaranKontra from '@/components/penjualan/GridPembayaranKontra'

export default {
  components: {
    CalendarFilter,
    GridPembayaranKontra,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      options: this.gridOptions,
      expandedRows: [],
      filters: {
        sp_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        payment_between: { value: null, matchMode: FilterMatchMode.CONTAINS },
        pelanggan: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.page = 1
      this.options.filters = this.filters
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
