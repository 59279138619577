<template>
  <div>
    <form @submit.prevent="save(!v$.$invalid)">
      <div class="card bg-white">
        <div class="flex justify-content-end mb-2">
          <Button
            :loading="isLoadingSaveHeader"
            icon="pi pi-save"
            class="mr-2"
            label="Simpan"
            type="submit"
          />
          <Button
            :loading="isLoadingInvoice"
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Faktur"
            @click="add"
          />
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'KontrabonPelanggan' })"
          />
        </div>
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="formgrid grid">
              <div class="field col-12 md:col-4">
                <label
                  :class="{
                    'p-error': v$.form.kb_at.$invalid && submitted,
                  }"
                >
                  Tanggal</label
                >
                <Calendar
                  v-model="form.kb_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                  :class="{
                    'p-invalid': v$.form.kb_at.$invalid && submitted,
                  }"
                  disabled
                />
                <small
                  v-if="
                    (v$.form.kb_at.$invalid && submitted) ||
                    v$.form.kb_at.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.kb_at.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-4">
                <label>No Kontra</label>
                <InputText v-model="form.kb_no" class="w-full" disabled />
              </div>
              <div class="field col-12 md:col-4">
                <label>Tanggal kembali</label>
                <Calendar
                  v-model="form.kembali_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                />
              </div>
              <div class="field col-12 md:col-8">
                <label
                  :class="{
                    'p-error': v$.form.pelanggan.$invalid && submitted,
                  }"
                >
                  Pelanggan
                </label>
                <AutoComplete
                  v-model="form.pelanggan"
                  :suggestions="acPelanggan"
                  :min-length="2"
                  :class="{
                    'p-invalid': v$.form.pelanggan.$invalid && submitted,
                  }"
                  class="w-full"
                  input-class="w-full"
                  field="nama"
                  :readonly="form.detail.length > 0"
                  @complete="searchPelanggan($event)"
                  @item-select="selectPelanggan"
                >
                  <template #item="slotProps">
                    <div>
                      <strong>
                        {{ slotProps.item.kode }} - {{ slotProps.item.nama }}
                      </strong>
                    </div>
                    <div>Alamat: {{ slotProps.item.alamat }}</div>
                    <div>Salesman: {{ slotProps.item.salesman.nama }}</div>
                  </template>
                </AutoComplete>
                <small
                  v-if="
                    (v$.form.pelanggan.$invalid && submitted) ||
                    v$.form.pelanggan.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.pelanggan.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-8">
                <label>Alamat</label>
                <InputText v-model="form.alamat" class="w-full" disabled />
              </div>
              <div class="field col-12 md:col-4">
                <label>Kota</label>
                <InputText v-model="form.kota" class="w-full" disabled />
              </div>
            </div>
          </div>
        </div>
        <!-- @edit="onEditInvoice" -->
        <grid-kontrabon-detail
          :items="form.detail"
          :loading="isLoading"
          @delete="onConfirmDeletion"
        />
      </div>
    </form>
    <Dialog
      header="Tambah invoice"
      v-model:visible="dialogAdd"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
      maximizable
    >
      <form-kontrabon-invoice
        :invoice="list_invoice"
        :exclude-list="list_invoice_id"
        @close="onCloseFormInvoice"
        @save="onSaveInvoice"
      />
    </Dialog>
    <!-- <Dialog
      header="Edit invoice"
      v-model:visible="dialogEdit"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
    >
      <form-Invoice :item="formEdit" @save="onUpdateInvoice" />
    </Dialog> -->
    <Dialog
      header="Hapus invoice dari kontra bon ?"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
    >
      <div>
        <span
          >Faktur <strong>{{ invoice.si_no }}</strong> akan dihapus dari kontra
          bon. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogHapus = false"
          class="p-button-text mr-2"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeleteInvoice"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import GridKontrabonDetail from '@/components/penjualan/GridKontrabonDetail'
import FormKontrabonInvoice from '@/components/penjualan/FormKontrabonInvoice'
// import FormInvoice from '@/components/penjualan/FormInvoice'
import SalesInvoiceService from '@/services/SalesInvoiceService'
import PelangganService from '@/services/PelangganService'
import KontraService from '@/services/KontraPenjualanService'
import { helpers, required } from '@vuelidate/validators'
import Hotkey from '@/components/Hotkey'
import errorHandler from '@/helpers/error-handler'
import dayjs from 'dayjs'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    GridKontrabonDetail,
    FormKontrabonInvoice,
    // FormInvoice,
    Hotkey,
  },
  data() {
    return {
      kontraService: null,
      pelangganService: null,
      submitted: false,
      isLoading: false,
      isLoadingSaveHeader: false,
      isLoadingInvoice: false,
      form: {
        id: 0,
        kb_at: new Date(),
        kb_no: null,
        pelanggan: null,
        detail: [],
      },
      acPelanggan: [],
      list_invoice: [],
      deleted: [],
      dialogAdd: false,
      // dialogEdit: false,
      dialogHapus: false,
      // formEdit: {},
      invoice: null,
      pelangganTop: 0,
    }
  },
  created() {
    this.pelangganService = new PelangganService()
    this.kontraService = new KontraService()
  },
  computed: {
    list_invoice_id() {
      return this.form.detail.map((x) => x.id)
    },
  },
  mounted() {
    if (this.id > 0) {
      this.isLoading = true
      this.kontraService
        .get(`/${this.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.form.kembali_at = this.form.kembali_at
              ? new Date(this.form.kembali_at)
              : null
            this.form.kb_at = this.form.kb_at ? new Date(this.form.kb_at) : null
            this.form.pelanggan = res.data.data.pelanggan
            this.form.alamat = res.data.data.pelanggan.alamat
            this.form.kota = res.data.data.pelanggan.kota

            if (this.form.detail) {
              if (this.form.detail.length > 0) {
                this.pelangganTop = this.form.pelanggan.top
                this.onComputeReturnDate()
              }
            }
          }
        })
        .catch((err) => {
          errorHandler(err, 'Kontra bon', this)
        })
        .finally(() => (this.isLoading = false))
    }
  },
  methods: {
    add() {
      if (!this.form.pelanggan) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Kontra bon',
          detail: 'Pelanggan harus diisi terlebih dahulu.',
          life: 3000,
        })
        return
      }
      // load invoice by pelanggan
      const salesInvoiceService = new SalesInvoiceService()
      const self = this
      self.isLoadingInvoice = true
      salesInvoiceService
        .get(
          `?filter[pelanggan_id]=${this.form.pelanggan.id}&filter[status]=done&filter[has_bon]=0`
        )
        .then((res) => {
          if (!res.data.data || res.data.data.length === 0) {
            this.$toast.add({
              severity: 'warn',
              summary: 'Kontra bon',
              detail: `Sales invoice untuk pelanggan ${this.form.pelanggan.nama} tidak ditemukan !.`,
              life: 3000,
            })
            self.isLoadingInvoice = false
            return
          } else {
            self.list_invoice = res.data.data
            self.dialogAdd = true
            self.isLoadingInvoice = false
          }
        })
        .catch(() => {
          self.isLoadingInvoice = false
        })
    },
    save(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      let form = {}
      let detail = []

      this.form.detail.forEach((el) => {
        let data = {}
        if (this.form.id === 0) {
          data.si_id = el.id
        } else if (this.form.id > 0 && el.si_id) {
          data.id = el.id
        } else if (this.form.id > 0 && !el.si_id) {
          data.id = 0
          data.si_id = el.id
        }

        detail.push(data)
      })

      form.id = this.form.id ? this.form.id : 0
      if (this.form.kb_no) {
        form.kb_no = this.form.kb_no
      }
      form.kb_at = this.form.kb_at
        ? dayjs(this.form.kb_at).format('YYYY-MM-DD HH:mm:ss')
        : null
      form.kembali_at = this.form.kembali_at
        ? dayjs(this.form.kembali_at).format('YYYY-MM-DD HH:mm:ss')
        : null
      form.pelanggan_id = this.form.pelanggan.id
      form.detail = detail

      if (form.id === 0) {
        this.isLoadingSaveHeader = true
        this.kontraService
          .add(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Kontra bon',
                detail: 'Data berhasil disimpan',
                life: 3000,
              })
              this.$router.push({ name: 'KontrabonPelanggan' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Kontra bon', this)
          })
          .finally(() => (this.isLoadingSaveHeader = false))
      } else {
        this.isLoadingSaveHeader = true
        form.deleted = this.deleted
        this.kontraService
          .update(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Kontra bon',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Kontra bon', this)
          })
          .finally(() => {
            this.deleted = []
            this.isLoadingSaveHeader = false
          })
      }
    },
    selectPelanggan(e) {
      this.form.alamat = e.value.pengiriman_alamat
      this.form.kota = e.value.pengiriman_kota
      this.pelangganTop = e.value.top
      // this.form.kembali_at = this.setTanggalKembali()
      this.onComputeReturnDate()
    },
    setTanggalKembali() {
      /*
      const result = dayjs(dayjs(this.form.kb_at).format('YYYY-MM-DD')).add(
        this.pelangganTop,
        'day'
      )
      return new Date(result.format(`YYYY/MM/DD`))
      */
      const top = this.pelangganTop
      const quotient = Math.floor(top / 30)
      const remainder = top % 30
      const result = dayjs(dayjs(this.form.kb_at).format('YYYY-MM-DD'))
        .add(quotient, 'month')
        .add(remainder, 'day'
      )
      return new Date(result.format(`YYYY/MM/DD`))
    },
    getTanggalKembaliOld(tgl_terima) {
      const top = this.pelangganTop
      const quotient = Math.floor(top / 30)
      const remainder = top % 30
      const result = dayjs(
        tgl_terima.add(quotient, 'month').add(remainder, 'day')
      )
      return new Date(result.format(`YYYY/MM/DD`))
    },
    getTanggalKembali(tgl_terima) {
      const top = this.pelangganTop
      let addDay = top
      for (let d = 1; d <= top; d++){
        const tmp_result = new Date(dayjs(tgl_terima.add(d, 'day')).format(`YYYY/MM/DD`))
        const date = tmp_result.getDate()
        const year = tmp_result.getFullYear()
        const month = tmp_result.getMonth()
        const days = new Date(year, month + 1, 0).getDate()
        let add = (days === 31 && date === days) ? 1 : 0
        addDay += add
      }
      let result = dayjs(tgl_terima.add(addDay, 'day'))
      return new Date(result.format(`YYYY/MM/DD`))
    },
    searchPelanggan(e) {
      this.pelangganService
        .get('?filter[nama]=' + e.query)
        .then((res) => {
          this.acPelanggan = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Pelanggan', this)
        })
    },
    onConfirmDeletion(item) {
      this.invoice = Object.assign({}, item)
      this.dialogHapus = true
    },
    onDeleteInvoice() {
      if (this.invoice.id && this.invoice.id > 0) {
        this.deleted.push(this.invoice.id)
      }
      const index = this.form.detail.findIndex(
        (el) => el.id === this.invoice.id && el.si_no === this.invoice.si_no
      )
      this.form.detail.splice(index, 1)
      this.invoice = {}
      this.dialogHapus = false
    },
    onSaveInvoice(item) {
      /*
      item.forEach((el) => {
        this.form.detail.push(el)
      })
      */
      let firstDate = null
      if (this.form.detail.length > 0) {
        let el = this.form.detail[0]
        firstDate = new Date(el.si_at)
      }
      for (let i = 0; i < item.length; i++) {
        let el = item[i]
        if (i === 0) {
          if (!firstDate) {
            firstDate = new Date(el.si_at)
            el.kembali_at = this.getTanggalKembali(dayjs(el.si_at))
          }
        }
        this.form.detail.push(el)
      }
      this.onComputeReturnDate()
      this.dialogAdd = false
    },
    /* onUpdateInvoice(item) {
      const index = this.form.detail.findIndex(
        (el) => el.id === item.id && el.pi_no === item.pi_no
      )

      this.form.detail[index] = item
      this.dialogEdit = false
    }, */
    onComputeReturnDate() {
      const detail = this.form.detail
      if (detail.length > 0) {
        // let isLate = false
        detail.forEach((el) => {
          el.kembali_at = this.getTanggalKembali(dayjs(new Date(el.si_at)))
        })
        /*
        const kontraDate = new Date(this.form.kb_at)
        const returnDate = new Date(detail[0].kembali_at)
        const hari = dayjs(returnDate).diff(kontraDate, 'day')

        isLate = hari <= 14

        this.form.detail.forEach((el) => {
          el.telat = isLate
        })
        if (isLate) {
          this.form.jenis_kontrabon = 'telat'
        }
        */
        this.form.kembali_at = detail[0].kembali_at
      }
    },
    onCloseFormInvoice() {
      this.dialogAdd = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.add()
      }
    },
  },
  validations() {
    return {
      form: {
        /* kembali_at: {
          required: helpers.withMessage(
            'Tanggal kembali harus diisi.',
            required
          ),
        }, */
        kb_at: {
          required: helpers.withMessage('Tanggal harus diisi.', required),
        },
        pelanggan: {
          required: helpers.withMessage('Pelanggan harus diisi.', required),
        },
      },
    }
  },
}
</script>
