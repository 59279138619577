<template>
  <div>
    <form @submit.prevent="save(!v$.$invalid)">
      <div class="card bg-white">
        <div class="flex justify-content-end mb-2">
          <Button
            :loading="isLoadingSaveHeader"
            :disabled="disableSaveButton"
            icon="pi pi-save"
            class="mr-2"
            label="Simpan"
            type="submit"
          />
          <Button
            :disabled="old_status_value !== 'draft' || disableSaveButton"
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Barang"
            @click="add"
          />
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'SalesOrder' })"
          />
        </div>
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="formgrid grid">
              <div class="field col-12 md:col-4">
                <label>Tanggal</label>
                <Calendar
                  v-model="form.sp_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>No SP</label>
                <InputText
                  v-model="form.sp_no"
                  class="w-full"
                  disabled
                  autofocus
                />
              </div>
              <div class="field col-12 md:col-4">
                <label
                  :class="{
                    'p-error': v$.form.golongan.$invalid && submitted,
                  }"
                >
                  Golongan
                </label>
                <Dropdown
                  v-model="v$.form.golongan.$model"
                  :options="list_golongan_obat"
                  :class="{
                    'p-invalid': v$.form.golongan.$invalid && submitted,
                  }"
                  :disabled="id > 0"
                  optionValue="id"
                  optionLabel="nama"
                  class="w-full"
                  autofocus
                />
                <small
                  v-if="
                    (v$.form.golongan.$invalid && submitted) ||
                    v$.form.golongan.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.golongan.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-4">
                <label
                  :class="{
                    'p-error': v$.form.pelanggan.$invalid && submitted,
                  }"
                >
                  Pelanggan
                </label>
                <AutoComplete
                  v-model="v$.form.pelanggan.$model"
                  :suggestions="acPelanggan"
                  :min-length="2"
                  :class="{
                    'p-invalid': v$.form.pelanggan.$invalid && submitted,
                  }"
                  class="w-full"
                  input-class="w-full"
                  field="nama"
                  @complete="searchPelanggan($event)"
                  @item-select="selectPelanggan"
                >
                  <template #item="slotProps">
                    <div v-if="slotProps.item">
                      <div>
                        <strong>
                          {{ slotProps.item.kode }} - {{ slotProps.item.nama }}
                        </strong>
                      </div>
                      <div>Alamat: {{ slotProps.item.alamat }}</div>
                      <div>Salesman: {{ slotProps.item.salesman.nama }}</div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                </AutoComplete>
                <small
                  v-if="
                    (v$.form.pelanggan.$invalid && submitted) ||
                    v$.form.pelanggan.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.pelanggan.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-4">
                <label
                  :class="{
                    'p-error': v$.form.salesman.$invalid && submitted,
                  }"
                >
                  Salesman
                </label>
                <Dropdown
                  v-model="v$.form.salesman.$model"
                  :options="list_salesman"
                  :class="{
                    'p-invalid': v$.form.salesman.$invalid && submitted,
                  }"
                  optionValue="id"
                  optionLabel="nama"
                  class="w-full"
                  disabled
                />
                <small
                  v-if="
                    (v$.form.salesman.$invalid && submitted) ||
                    v$.form.salesman.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.salesman.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-4">
                <label>Status</label>
                <Dropdown
                  v-model="form.status"
                  :options="list_status"
                  :disabled="form.id === 0 || old_status_value === 'pick'"
                  optionValue="value"
                  optionLabel="label"
                  optionDisabled="disabled"
                  class="w-full"
                  input-class="w-full"
                />
              </div>
              <div v-if="!isSPValid" class="col-12">
                <Message :severity="severity" :closable="false">{{
                  isSPValidMessage
                }}</Message>
              </div>
              <div class="field col-12">
                <label>Keterangan</label>
                <InputText v-model="form.keterangan" class="w-full" />
              </div>
            </div>
          </div>
        </div>
        <grid-sales-order-detail
          :items="form.obat"
          :loading="isLoading"
          :status="old_status_value"
          @edit="onEditBarang"
          @delete="onConfirmDeletion"
        />
      </div>
    </form>
    <Dialog
      :header="productIndex >= 0 ? 'Edit Barang' : 'Tambah Barang'"
      v-model:visible="dialogAdd"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <form-barang
        :loading="isLoadingSaveBarang"
        :item="product"
        :satuan="list_satuan"
        :kodeHarga="kode_harga"
        :golongan="golongan"
        @close="onCloseFormBarang"
        @save="onSaveBarang"
      />
    </Dialog>
    <Dialog
      header="Hapus barang"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div>
        <span
          >Obat <strong>{{ product.nama_obat }}</strong> akan dihapus dari
          daftar pesanan. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogHapus = false"
          class="p-button-text mr-2"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeleteBarang"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D', 'S']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import GolonganObatService from '@/services/GolonganObatService'
import ObatService from '@/services/ObatService'
import PelangganService from '@/services/PelangganService'
import SalesOrderService from '@/services/SalesOrderService'
import SalesmanService from '@/services/SalesmanService'
import SettingService from '@/services/SettingService'
import errorHandler from '@/helpers/error-handler'
import FormBarang from '@/components/penjualan/FormObat'
import GridSalesOrderDetail from '@/components/penjualan/GridSalesOrderDetail'
import Hotkey from '@/components/Hotkey'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    FormBarang,
    GridSalesOrderDetail,
    Hotkey,
  },
  data() {
    return {
      submitted: false,
      salesOrderService: null,
      pelangganService: null,
      list_golongan_obat: null,
      list_salesman: null,
      acPelanggan: [],
      dialogAdd: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSaveHeader: false,
      isLoadingSaveBarang: false,
      productIndex: -1,
      product: null,
      kode_harga: '',
      list_satuan: [],
      deleted: [],
      form: {
        id: 0,
        sp_at: new Date(),
        sp_no: '',
        pelanggan: null,
        golongan: null,
        salesman: null,
        keterangan: null,
        status: 'draft',
        obat: [],
      },
      sia_validity: null,
      sipa_validity: null,
      severity: 'warn',
      disableSaveButton: false,
      isSPValid: true,
      isSPValidMessage: '',
      validityMonth: 0,
      old_status_value: 'draft',
      ppn: 0,
    }
  },
  created() {
    this.pelangganService = new PelangganService()
    this.salesOrderService = new SalesOrderService()
    const settingService = new SettingService()

    settingService.get('?filter[key]=VALIDITY_MONTH').then((res) => {
      this.validityMonth = res.data.data.value
    })

    settingService.get('?filter[key]=PPN').then((res) => {
      this.ppn = res.data.data.value
    })
  },
  computed: {
    isSiaExpired() {
      if (!this.sia_validity) {
        return true
      }

      const valid = dayjs(this.sia_validity).subtract(
        this.validityMonth,
        'month'
      )

      const now = dayjs()
      const diff = valid.diff(now, 'day')

      return diff < 0
    },
    isSipaExpired() {
      if (!this.sipa_validity) {
        return true
      }

      const valid = dayjs(this.sipa_validity).subtract(
        this.validityMonth,
        'month'
      )

      const now = dayjs()
      const diff = valid.diff(now, 'day')

      return diff < 0
    },
    golongan() {
      let result = null
      const item = this.list_golongan_obat.find(
        (e) => e.id === this.form.golongan
      )
      if (item) {
        result = item.nama
      }
      return result
    },
    list_status() {
      const list = [
        { value: 'draft', label: 'Draft', level: 0 },
        { value: 'sent', label: 'Sent', level: 1 },
        { value: 'valid', label: 'Valid', level: 2 },
        { value: 'pick', label: 'Pick', level: 3 },
      ]
      let old_status_level = 0
      if (this.old_status_value) {
        const status = list.find((e) => e.value === this.old_status_value)
        if (status) old_status_level = status.level
      }
      return list.map((status) => ({
        ...status,
        disabled: old_status_level > status.level,
      }))
    },
  },
  mounted() {
    const self = this
    Promise.all([this.getGolonganObat(), this.getSalesman()]).then(function (
      result
    ) {
      self.list_golongan_obat = result[0]
      self.list_salesman = result[1]
    })

    if (this.id > 0) {
      this.isLoading = true
      this.salesOrderService
        .get(`/${this.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            let obat = res.data.data.obat
            obat.forEach((o) => {
              o.harga_diskon_display = o.harga * (o.diskon / 100)
              /*o.harga_total = o.dpp + Math.floor((o.dpp * self.ppn) / 100)*/
            })
            this.form.obat = obat
            this.kode_harga = res.data.data.pelanggan.kode_harga
            this.form.sp_at = this.form.sp_at ? new Date(this.form.sp_at) : null
            this.form.golongan = res.data.data.golongan.id
            this.form.salesman = res.data.data.salesman.id
            this.old_status_value = this.form.status
          }
        })
        .catch((err) => {
          errorHandler(err, 'Sales Order', this)
        })
        .finally(() => (this.isLoading = false))
    }
  },
  methods: {
    /*
    getListStatus() {
      let status = []
      switch (this.old_status_value) {
        case 'draft':
          //status = ['draft', 'sent']
          status = [
            { value: 'draft', label: 'Draft', level: 0 },
            { value: 'sent', label: 'Sent', level: 1 },
            { value: 'valid', label: 'Valid', level: 2 },
            { value: 'pick', label: 'Pick', level: 3 },
          ]
          break
        case 'sent':
          status = ['sent', 'valid']
          break
        case 'valid':
          status = ['valid']
          break
        case 'pick':
          status = ['pick']
          break
        default:
          status.push('draft')
          break
      }
      this.list_status = status
    },
    */
    validityCheck() {
      if (this.isSiaExpired && this.isSipaExpired) {
        this.disableSaveButton = true
        this.isSPValid = false
        this.isSPValidMessage = 'Masa berlaku ijin SIA & SIPA habis !'
        this.severity = 'error'
        return true
      }

      if (this.isSiaExpired && !this.isSipaExpired) {
        this.isSPValid = false
        this.isSPValidMessage = 'Masa berlaku ijin SIA habis !'
        this.disableSaveButton = false
        this.severity = 'warn'
        return true
      }

      if (!this.isSiaExpired && this.isSipaExpired) {
        this.disableSaveButton = true
        this.isSPValid = false
        this.isSPValidMessage = 'Masa berlaku ijin SIPA habis !'
        this.severity = 'error'
        return true
      }

      this.disableSaveButton = false
      this.isSPValid = true
      this.isSPValidMessage = ''
    },
    async getGolonganObat() {
      const golonganObatService = new GolonganObatService()
      return await golonganObatService
        .get('?sort=nama')
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data golongan obat', this)
        })
    },
    async getSalesman() {
      const salesmanService = new SalesmanService()
      return await salesmanService
        .get('?sort=nama')
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data salesman', this)
        })
    },
    selectPelanggan(e) {
      this.sia_validity = e.value.sia_validity
      this.sipa_validity = e.value.sipa_validity
      this.kode_harga = e.value.kode_harga
      this.form.salesman = e.value.salesman.id
      this.validityCheck()
    },
    searchPelanggan(e) {
      this.pelangganService
        .get('?filter[nama]=' + e.query + '&filter[status]=aktif')
        .then((res) => {
          this.acPelanggan = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Pelanggan', this)
        })
    },
    add() {
      this.productIndex = -1

      if (!this.form.pelanggan || !this.form.golongan) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Sales Order',
          detail: 'Pelanggan dan golongan harus diisi terlebih dahulu.',
          life: 3000,
        })
        return
      }

      this.product = {
        id: 0,
        nama: '',
        obat_id: 0,
        quantity: 1,
        quantity_tkcl: 0,
        diskon: 0,
        diskon_harga: 0,
        harga: 0,
        harga_diskon: 0,
        harga_diskon_display: 0,
        dpp: 0,
        harga_total: 0,
        keterangan: '',
      }
      this.dialogAdd = true
    },
    async onEditBarang(item) {
      this.product = Object.assign({}, item)

      this.productIndex = this.form.obat.findIndex(
        (el) => el.id === item.id && el.obat_id === item.obat_id
      )
      const obatService = new ObatService()

      const obat = await obatService.get(`/${item.obat_id}`).then((res) => {
        if (res.data.status === 200) {
          return {
            id: item.obat_id,
            kode: res.data.data.kode,
            nama: item.nama_obat,
            satuan: res.data.data.satuan_order,
          }
        }
      })

      this.list_satuan = obat.satuan
      this.product.obat = obat
      const res = this.list_satuan.find(
        (el) => el.id === this.product.satuan_id
      )
      this.product.satuan = this.product.satuan_id
      if (res) this.product.qty_satuan = res.quantity
      this.dialogAdd = true
    },
    onConfirmDeletion(item) {
      this.product = Object.assign({}, item)
      this.dialogHapus = true
    },
    onDeleteBarang() {
      if (this.product.id !== 0) {
        this.deleted.push(this.product.id)
      }
      const index = this.form.obat.findIndex(
        (el) => el.id === this.product.id && el.obat_id === this.product.obat_id
      )
      this.form.obat.splice(index, 1)
      this.productIndex = -1
      this.product = {}
      this.dialogHapus = false
    },
    save(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      const form = Object.assign({}, this.form)

      form.sp_at = this.form.sp_at
        ? dayjs(this.form.sp_at).format('YYYY-MM-DD HH:mm:ss')
        : null

      form.golongan_id = this.form.golongan
      form.salesman_id = this.form.salesman
      form.pelanggan_id = this.form.pelanggan.id

      /*
      let obat = form.obat
      obat.forEach((o) => {
        delete o.harga_diskon_display
        delete o.harga_total
      })
      form.obat = obat
      */

      delete form.golongan
      delete form.salesman
      delete form.pelanggan

      if (!this.form.keterangan) {
        delete form.keterangan
      }

      if (this.form.id === 0) {
        this.isLoadingSaveHeader = true
        this.salesOrderService
          .add(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Sales Order',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.$router.push({ name: 'SalesOrder' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Sales order', this)
          })
          .finally(() => (this.isLoadingSaveHeader = false))
      } else {
        this.isLoadingSaveHeader = true
        form.deleted = this.deleted
        this.salesOrderService
          .update(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Sales Order',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Sales order', this)
          })
          .finally(() => {
            this.deleted = []
            this.isLoadingSaveHeader = false
            this.refreshDetail()
          })
      }
    },
    refreshDetail() {
      if (this.form.id > 0) {
        this.salesOrderService.get(`/${this.form.id}`).then((res) => {
          if (res.data.status === 200) {
            this.form.obat = res.data.data.obat
            this.form.obat.forEach((el) => {
              el.harga_diskon_display = el.harga * (el.diskon / 100)
              /*el.harga_total = el.dpp + Math.floor((el.dpp * self.ppn) / 100)*/
            })
          }
        })
      }
    },
    onSaveBarang(item) {
      this.product = Object.assign({}, item)
      if (this.productIndex >= 0) {
        Object.assign(this.form.obat[this.productIndex], this.product)
        this.dialogAdd = false
      } else {
        let has_duplicate = false
        // validasi duplikat obat
        this.form.obat.forEach((el) => {
          el.harga_diskon_display = el.harga * (el.diskon / 100)
          /*el.harga_total = el.dpp + Math.floor((el.dpp * self.ppn) / 100)*/
          if (el.obat_id === this.product.obat_id) {
            has_duplicate = true
          }
        })

        if (has_duplicate) {
          this.$toast.add({
            severity: 'warn',
            summary: 'Sales Order',
            detail: 'Duplikasi data obat ! data tidak dapat ditambahkan.',
            life: 3000,
          })
        } else {
          this.form.obat.push(this.product)
          this.$toast.add({
            severity: 'success',
            summary: 'Sales Order',
            detail: 'Data obat berhasil ditambahkan.',
            life: 3000,
          })
        }
      }
      this.isLoadingSaveBarang = false
    },
    onCloseFormBarang() {
      this.dialogAdd = false
    },
    onTriggerHotkey(payload) {
      switch (payload.keyString) {
        case 'D':
          this.add()
          break
        case 'S':
          this.save(!this.v$.$invalid)
          break
      }
    },
  },
  validations() {
    return {
      form: {
        golongan: {
          required: helpers.withMessage('Golongan harus diisi.', required),
        },
        pelanggan: {
          required: helpers.withMessage('Pelanggan harus diisi.', required),
        },
        salesman: {
          required: helpers.withMessage('Salesman harus diisi.', required),
        },
      },
    }
  },
}
</script>
